//Componente en construcción con el formato deevaluación de desempeño accesible desde correo para el evaluador
import React, {useState, useEffect} from 'react';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Logo from './usoGeneral/logo.png';
import {GLOBAL, THISGLOBAL} from "./usoGeneral/Global";
import {FormControl, InputLabel, Select, OutlinedInput, MenuItem, Button} from '@material-ui/core';
import { Card, CardContent, ListItemText } from "@material-ui/core";

import SendteIcon from '@material-ui/icons/Send';
import { Radio, FormControlLabel, RadioGroup} from '@material-ui/core';
import axios from 'axios';
import 'typeface-roboto';
import {styles} from './usoGeneral/estilos';
import Swal from "sweetalert2";

function Evaluacion(props) {

    const [ control , setControl ] = useState(0);// Controlador del useEffect
    const [ selRecurso , setSelRecurso ] = useState("");// Seleccion del recurso
    const { classes } = props;
    const [ evaluador , setEvaluador ] = useState({});
    const [ arrayRecursos, setArrayRec ] = useState([]);
    const [ recurso , setRecurso ] = useState({_id: ""});
    const [ cuest , setCuest ] = useState([]);//Arreglo de preguntas y respuestas
    const [ respArray , setRespArray ] = useState([]);//arreglo de respuestas seleccionadas en orden
    const [ idEval , setIdEval ] = useState(""); //id del registro de evaluacion asociado

    const handlerSelRec = (e) => {
        borrar();
        setSelRecurso(e.target.value);
        let url = GLOBAL+"registro?tipo=9&selRec="+e.target.value+"";
        let url2 = GLOBAL+"registro"+window.location.search+"&tipo=10";
        axios.get(url, {headers: {'Content-Type': 'application/json'}})
        .then(response => {
            if (response.data === null) {
            } else {
                // console.log(response.data);
                setRecurso(response.data);
            }
        })
        .catch(error => console.log(error));

        axios.get(url2, {headers: {'Content-Type': 'application/json'}})
        .then(response => {
            if (response.data === null) {
            } else {
                // console.log(response.data);
                setIdEval(response.data.id);
                setCuest(response.data.cue.preguntas);
                let copy = [];
                response.data.cue.preguntas.forEach(function(preg){
                    copy.push("");
                });
                setRespArray(copy);
            }
        })
        .catch(error => console.log(error));

    };

    const rightAnswer = (index, res) =>{
        const copia = respArray;
        copia[index] = res.target.value;
        setRespArray(copia);
        console.log(copia);
    };

    const validacion = () => {
        let contador = 0;

        respArray.forEach(function(resp){
            if(resp === ""){
                contador += 1;
            }
        });
        console.log(respArray);

        if(contador > 0){
            Swal.fire({
                type: 'warning',
                title: 'Por favor contesta todas las preguntas.',
                showConfirmButton: true,
                timer: 1500
            });
        }else{
            let bdy = {
                tipo: 4,
                idEvaluacion : idEval,
                idRecurso: selRecurso,
                respuestas: respArray
            };
            let url3 = GLOBAL + "registro";

            console.log(bdy);

            axios.put(url3, bdy, {headers: {'Content-Type': 'application/json'}})
            .then(response => {
                if (response.data === null) {
                } else {
                    Swal.fire({
                        type: 'success',
                        title: 'Respuestas enviadas con éxito.',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    borrar();  
                }
                
            })
            .catch(error => console.log(error));
        }

    };

    const borrar = () => {
        setSelRecurso("");
        setArrayRec([]);
        setCuest([]);
        setRespArray([]);
        setIdEval("");
        setRecurso({_id: ""});
        loader();
    };

    const loader = () => {
        let url = GLOBAL+"registro"+window.location.search+"&tipo=8";
        axios.get(url, {headers: {'Content-Type': 'application/json'}})
            .then(response => {
                if (response.data === null) {
                } else {
                    // console.log(response.data);
                    if(!response.data.activo){
                        window.location.replace(THISGLOBAL+"evaluacion/salir");
                    }
                    setEvaluador(response.data.evaluador);
                    setArrayRec(response.data.lista);
                    setIdEval(response.data.idDes);
                    setControl(1);
                    console.log(response.data);
                }
            })
            .catch(error => console.log(error));
    };

    useEffect(() => {
        if(control === 0){
          loader(); 
        }
    }, []);

    return(
        <div>
            <Grid container direction='row' justify='center' alignItems='center' spacing={3}>

                <Grid item xs={12} sm={12}>
                    <AppBar position="fixed" color="default" style={{ backgroundColor: '#ee7203' }} >
                        <Toolbar style={{ color: "#fafafa" }}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item>
                                    <img src={Logo} alt="Logo" width="40" height="40" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" color="inherit">
                                        Evaluación de Desempeño
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                </Grid>
                <br/><br/><br/>
                <br/>
                <Grid item sm={8} xs={11}>
                    <Card>
                        <CardContent>

                            <Grid container spacing={2} alignItems="center" justify="center">

                                <Grid item sm={12}><Typography variant="h3" color="inherit" align="left">{evaluador.S_evaNombre}</Typography></Grid>

                                <Grid item sm={12}><Typography variant="h6" color="inherit" align="left">{evaluador.S_evaEntidad}</Typography></Grid>

                                <Grid item sm={6} xs={12}><Typography variant="h6" color="inherit" align="right">Recursos por evaluar:</Typography></Grid>
                                <Grid item sm={6} xs={12}>
                                    <FormControl variant="outlined" fullWidth={true} >
                                        <InputLabel>Recursos</InputLabel>
                                        <Select
                                        value={selRecurso}
                                        onChange={handlerSelRec}
                                        input={<OutlinedInput labelWidth={70} />}
                                        >
                                        {
                                            arrayRecursos.map((data, index) => {
                                                return(
                                                    <MenuItem key={index} value={data.AI_desRecurso}>{data.AS_desRecurso}</MenuItem>
                                                )
                                            })
                                        }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>

                <Grid item sm={8} xs={11}>
                    { //Para visualizar la tarjeta de informacion del recurso seleccionado
                        recurso._id === "" ? false : <Card >
                            <Grid container direction='row' spacing={2} justify="space-around" alignItems="center">

                                <Grid item sm={4} xs={11}>
                                    <div align='center'>
                                        <img src={recurso.S_recImagen} alt="" height='200px' width='200px' className={classes.ImagenGrande}/>
                                    </div>  
                                </Grid>

                                <Grid item sm={8} xs={11}>
                                    <Typography variant='h3' color="inherit" align="center">{recurso.S_recNombre}</Typography>
                                    <Typography variant='h6' color="inherit" align="center">{recurso.S_recEntidad}</Typography>
                                    <Typography variant='body1' color="inherit" align="center">{recurso.S_recCorreo}</Typography>
                                </Grid>

                            </Grid>
        
                        </Card>
                    }
                </Grid>

                {/* {
                    cuest.length !== 0 ? true : <Grid item sm={8} xs={11}><Card>
                        <CardContent>
                            <Typography align='center'>Recurso evaluado con éxito</Typography>
                        </CardContent>
                    </Card></Grid>
                } */}
                
                
                {
                    cuest.map((data, index) => {
                        return(
                            <Grid item sm={8} xs={11} key={index}>
                                <Card>

                                    <AppBar position="static">
                                            <Toolbar variant="dense" className={classes.SecondaryLight}>
                                                <ListItemText  primary={<Typography variant="h6" >{data.pregunta}</Typography>}/>
                                            </Toolbar>
                                        </AppBar>

                                    <CardContent>

                                        <Grid container direction="row">
                                            <RadioGroup  value={respArray[index]} onChange={rightAnswer.bind(this, index)} >
                                                {   data.respuestas.map((post, idx) => {
                                                    return (                   
                                                        <FormControlLabel key={idx} value={post.respuesta } control={<Radio style={{ color: '#ee7203' }} />} label={post.respuesta} />
                                                    );
                                                    })}
                                            </RadioGroup>
                                        </Grid>
                                
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })
                }

                {
                    cuest.length <= 0 ? true : <Grid item sm={8} xs={11}>
                        <Grid container direction='row' justify='center' alignItems='center'>
                            <Grid item xs={12} sm={8}>
                            <Button variant="contained" color="primary" className={classes.BotonSend} fullWidth={true} onClick={validacion}>
                                Enviar  &nbsp; Respuestas &nbsp;
                                <SendteIcon  />
                            </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }
           
            </Grid>
            
        </div>
    );
}
Evaluacion.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Evaluacion);