import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet
} from "@react-pdf/renderer";

const PdfDocument = props => {
  const styles = StyleSheet.create({
    // Estilos para los componentes dentro de la sección header
    headerContainer: {
      margin: 10,
      padding: 20
    },
    nameContainer: {
      display: "flex",
      flexDirection: "row"
    },
    nameLabel: {
      display: "flex"
    },
    nameValue: {
      display: "flex"
    },
    emailContainer: {
      display: "flex",
      flexDirection: "row"
    },
    emailLabel: {
      display: "flex"
    },
    emailValue: {
      display: "flex"
    },
    // Divider
    divider: {
      width: "100%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    // Estilos para los componentes dentro de la sección body
    resultContainer: {
      margin: 10,
      padding: 20
    },
    // Nombre del examen
    testContainer: {
      display: "flex",
      flexDirection: "row"
    },
    testLabel: {
      display: "flex"
    },
    testValue: {
      display: "flex"
    },
    // Tabla
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginTop: 15
    },
    tableRow: { margin: "auto", flexDirection: "row" },
    tableCol: {
      width: "5%",
      borderStyle: "solid",
      borderWidth: 1,
      borderTopWidth: 0
    },
    tableCol1: {
      width: "75%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCol2: {
      width: "10%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCol3: {
      width: "10%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCol4: {
      width: "20%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCell: { margin: "auto", marginTop: 5, fontSize: 10 },
    // No se realizo el examen
    noAnswerTilte: {
      fontSize: 12
    }
  });

  const onCreateHeaderHandler = () => {
    const data = props.data;
    return (
      <View style={styles.headerContainer}>
        <View style={styles.nameContainer}>
          <Text style={styles.nameLabel}>Nombre: </Text>
          <Text style={styles.nameValue}>{data.name}</Text>
        </View>
        <View style={styles.emailContainer}>
          <Text style={styles.emailLabel}>Correo: </Text>
          <Text style={styles.emailValue}>{data.email}</Text>
        </View>
      </View>
    );
  };

  const onCreateTableHandler = data => {
    return (
      <View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Pregunta</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Correcta</Text>
            </View>
            <View style={styles.tableCol3}>
              <Text style={styles.tableCell}>Incorrecta</Text>
            </View>
          </View>
        </View>
        {data.map((res, i) => {
          return (
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{i + 1}</Text>
              </View>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>{res._id}</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>
                  {res.correcta === 1 ? "X" : ""}
                </Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>
                  {res.correcta === 0 ? "X" : ""}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    );
  };

  const onCreateBodyHandler = () => {
    const data = props.data;
    let resultData = null;
    let showOtherData = null;

    resultData = data.tecnologias.map(tec => {
      let showResData =
        tec.respuestas && tec.respuestas.length > 0
          ? onCreateTableHandler(tec.respuestas[0])
          : null;

      showOtherData = (
        <View>
          <Text style={styles.noAnswerTilte}>No se realizo el examen.</Text>
        </View>
      );

      if (showResData) {
        showOtherData = (
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell}>Total preguntas</Text>
              </View>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell}>Contestadas</Text>
              </View>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell}>Correctas</Text>
              </View>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell}>Incorrectas</Text>
              </View>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell}>Calificación</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell}>{tec.totalPreguntas}</Text>
              </View>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell}>{tec.contestadas}</Text>
              </View>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell}>{tec.correctas}</Text>
              </View>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell}>{tec.incorrectas}</Text>
              </View>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell}>{tec.calificacion}</Text>
              </View>
            </View>
          </View>
        );
      }

      return (
        <View style={styles.resultContainer}>
          <View style={styles.testContainer}>
            <Text style={styles.testLabel}>Nombre del examen: </Text>
            <Text style={styles.testValue}>{tec.nombreCuestionario}</Text>
          </View>
          {showOtherData}
          <View style={styles.tableContainer}>{showResData}</View>
        </View>
      );
    });
    return resultData;
  };

  return (
    <Document>
      <Page>
        <View>{onCreateHeaderHandler()}</View>
        <View style={styles.divider}></View>
        <View>{onCreateBodyHandler()}</View>
      </Page>
    </Document>
  );
};

export default PdfDocument;
