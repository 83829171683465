/* eslint-disable no-loop-func */
import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Logo from './usoGeneral/logo.png';
import { green, blue, amber, red } from '@material-ui/core/colors';
import 'typeface-roboto';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import axios from 'axios';
import Burger from './Menu'
import Swal from "sweetalert2";
import {GLOBAL} from './usoGeneral/Global';
import { Button } from '@material-ui/core';
import CanvasJSReact from './secundarios/canvasjs.react';
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const styles = theme => ({
    card: {
        minWidth: 275,
    },
    contenedor: {
        position: 'static',
        top: 100,
    },
    fabButton1: {
        position: 'absolute',
        top: 7,
        right: 200,
        margin: '0 auto',
        background: amber[700],
        "&:hover": {
            background: amber[500],
        }
    },
    fabButton11: {
        position: 'relative',
        margin: '0 auto',
        background: "#ee7203",
        "&:hover": {
            background: amber[500],
        }
    },
    fabButton2: {
        position: 'absolute',
        top: 7,
        right: 125,
        margin: '0 auto',
        background: amber[700],
        "&:hover": {
            background: blue[500],
        }
    },
    fabButton3: {
        position: 'absolute',
        top: 7,
        right: 50,
        margin: '0 auto',
        background: amber[700],
        "&:hover": {
            background: green[500],
        }
    },
    botonDeBorrado: {
        position: 'absolute',
        bottom: -20,
        left: 0,
        margin: '0 auto',
        background: red[500],
        "&:hover": {
            background: red[300],
        }
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    lista: {
        width: '100%',
        maxWidth: 400,
        maxHeight: 200,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
    },
    botonSend: {
        position: 'relative',
        margin: '0 auto',
        background: "#3aa832",
        "&:hover": {
            background: "#36b82c",
        }
    },
});

function Consulta(props){

    const { classes } = props;

    const [ entidades , setEntidades ] = useState([]);
    const [ recursos , setRecursos ] = useState([]);
    const tiempos = [{num: 0, mes:"Enero"},{num: 1, mes:"Febrero"},{num: 2, mes:"Marzo"},{num: 3, mes:"Abril"},{num: 4, mes:"Mayo"},{num: 5, mes:"Junio"},
    {num: 6, mes:"Julio"},{num: 7, mes:"Agosto"},{num: 8, mes:"Septiembre"},{num: 9, mes:"Octubre"},{num: 10, mes:"Noviembre"},{num: 11, mes:"Diciembre"},
    {num: 12, mes:"Todos los registros"}];

    const [ selEnt , setSelEnt ] = useState("");
    const [ selEval , setSelEval ] = useState("");
    const [ selTemp , setSelTemp ] = useState("");
    const [ control , setControl ] = useState(0);
    const [ formato , setFormato ] = useState([]);

    const handlerSelectTemp = (e) => {
        setSelTemp(e.target.value);
    };

    const handlerSelectEnt = (e) => {
        setSelEnt(e.target.value);
        setSelEval("");
    };

    const handlerSelectEval = (e) => {
        setSelEval(e.target.value);
    };

    const formatoDatos = (data, answers) => {
        let format = [];

        CanvasJS.addColorSet("correctColors", [//colorSet Array
            "#006747",	
            "#009639",	
            "#78be20",	
            "#00a499",	
            "#404e4d"
            ]);

        //Llena el formato de opciones necesario para graficar
        for (var i = 0; i < data.length; i++){
            let preguntas = [];
            let auxi = data[i].respuestas;

            auxi.forEach((element) => {
                preguntas.push({label: element.respuesta, y: 0});
            });

            format.push({colorSet: "correctColors", title: {text: data[i].pregunta}, data: [{type: 'column', dataPoints: preguntas}] });
        }

        //llena las respuestas en el formato
        for (var j = 0; j < format.length; j++){
            let auxi2 = format[j].data[0].dataPoints;

            auxi2.forEach((elemento, index) => {
                if(elemento.label=== answers[j]){
                    format[j].data[0].dataPoints[index].y += 1;
                }
            });

        }

        console.log(format);
        setFormato(format);
    };

    const check = () => {
        if( selEnt === ""){
            Swal.fire({
                type: 'warning',
                title: 'Selecciona una entidad válida.',
                showConfirmButton: false,
                timer: 1500
            });
        }else{
            if(selEval === ""){
                Swal.fire({
                    type: 'warning',
                    title: 'Selecciona un recurso válido.',
                    showConfirmButton: false,
                    timer: 1500
                });
            }else{
                if(selTemp === ""){
                    Swal.fire({
                        type: 'warning',
                        title: 'Selecciona un período válido.',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }else{
                    checktipo();
                }
            }

        }
    };

    const checktipo = () => {
        let type = 0;

        if(selTemp === 12){
            type = 2;
        }else{
            type = 1;
        }

        let evaluadorID = '';
        for (var i = 0; i < recursos.length; i++){
            if(recursos[i]._id === selEval){
                evaluadorID = recursos[i].I_recEvaluador
            }
        }

        

        let params = "?tipo="+type+"&m="+selTemp+"&e="+selEnt+"&v="+evaluadorID+"&r="+selEval+"";
        console.log(GLOBAL+"consultas"+params);
        axios.get(GLOBAL+"consultas"+params)
            .then(resp => {
                // console.log(resp);
                if(resp.data === "" || resp.data.length === 0){
                    Swal.fire({
                        type: 'warning',
                        title: 'No se encontraron registros bajo esos parámetros.',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }else{
                    // console.log(resp.data.respuestas);
                    if(type===1){
                        formatoDatos(resp.data.cuestionario, resp.data.respuestas);
                        Swal.fire({
                            type: 'success',
                            title: 'Búsqueda exitosa.',
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }else{
                        if(type === 2){

                            Swal.fire({
                                type: 'success',
                                title: 'Búsqueda exitosa.',
                                showConfirmButton: false,
                                timer: 1500
                            });
                        }
                    }
                }
            })
            .catch(error => {
                Swal.fire({
                    type: 'warning',
                    title: 'No se encontraron registros bajo esos parámetros.',
                    showConfirmButton: false,
                    timer: 1500
                });
            console.log(error);
        });

    };

    useEffect(() => {
        if(control === 0){
            axios //traemos entidades en BD
                .get(GLOBAL+'registro?tipo=4')
                .then(response => {
                    let auxi1 = response.data;
                    setEntidades(auxi1);
                })
                .catch(error => {
                    console.log(error);
                });

            axios //traemos evaluadores en BD
                .get(GLOBAL+'registro?tipo=11')
                .then(response => {
                    let auxi2 = response.data;
                    setRecursos(auxi2);
                    setControl(1);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    });

    return(
        <div>
            <Grid container direction='row' justify='center' alignItems='center' spacing={3}>

                <Grid item sm={12} xs={12}>
                    <AppBar position="fixed" color="default" style={{ backgroundColor: '#ee7203' }} >
                        <Toolbar style={{ color: "#fafafa" }}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item>
                                    <img src={Logo} alt="Logo" width="40" height="40" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" color="inherit">
                                        Consultas
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Burger />
                        </Toolbar>
                    </AppBar>
                </Grid>
                <br/><br/><br/><br/>

                <Grid item sm={8} xs={11}>

                    <Card>

                        <CardContent>

                            <Grid container spacing={3} alignItems="center" justify="space-evenly">

                                <Grid item sm={12} xs={12}>
                                    <Typography variant="h5" color="inherit" align="center">
                                       Filtros para consulta:
                                    </Typography>  
                                </Grid>

                                <Grid item sm={4} xs={12}>
                                    <FormControl variant="outlined" fullWidth={true} >
                                        <InputLabel>Entidades</InputLabel>
                                        <Select
                                        value={selEnt}
                                        onChange={handlerSelectEnt}
                                        input={<OutlinedInput labelWidth={70} />}
                                        >
                                        {
                                            entidades.map((data, index) => {
                                                return(
                                                    <MenuItem key={index} value={data._id}>{data.S_entNombre}</MenuItem>
                                                )
                                            })
                                        }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item sm={4} xs={12}>
                                {
                                    selEnt === "" ? 
                                    <FormControl variant="outlined" fullWidth={true} disabled>
                                    <InputLabel>Recursos</InputLabel>
                                    <Select
                                    value={selEval}
                                    onChange={handlerSelectEval}
                                    input={<OutlinedInput labelWidth={55} />}
                                    >
                                    </Select>
                                </FormControl> : <FormControl variant="outlined" fullWidth={true}>
                                        <InputLabel>Recursos</InputLabel>
                                        <Select
                                        value={selEval}
                                        onChange={handlerSelectEval}
                                        input={<OutlinedInput labelWidth={85} />}
                                        >
                                        {
                                            recursos.map((data, index) => {
                                                return(
                                                    data.I_recEntidad === selEnt ? <MenuItem key={index} value={data._id}>{data.S_recNombre}</MenuItem> : false
                                                )
                                            })
                                        }
                                        </Select>
                                    </FormControl>
                                }
                                </Grid>

                                <Grid item sm={4} xs={12}>
                                    <FormControl variant="outlined" fullWidth={true} >
                                        <InputLabel>Periodo de Tiempo</InputLabel>
                                        <Select
                                        value={selTemp}
                                        onChange={handlerSelectTemp}
                                        input={<OutlinedInput labelWidth={135} />}
                                        >
                                        {
                                            tiempos.map((data, index) => {
                                                return(
                                                    <MenuItem key={index} value={data.num}>{data.mes}</MenuItem>
                                                )
                                            })
                                        }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    <Button variant="contained" color="primary" className={classes.botonSend} fullWidth={true} onClick={check}>
                                        Consultar
                                    </Button>
                                </Grid>

                            </Grid>

                        </CardContent>

                    </Card>

                </Grid>

                <Grid item sm={8} xs={11} >
                    <Grid container direction='column' spacing={2} justify='center'>
                    {
                        formato.map( (datos, index) => {
                            return(
                                <Grid item sm={12} xs={12} key={index}>
                                    <Card>
                                        <CardContent>
                                            <CanvasJSChart options={datos} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                    </Grid>
                </Grid>


            </Grid>
        </div>
    );
}
Consulta.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Consulta);