import React, {useState, useEffect} from "react";
import axios from 'axios';
import MaterialTable from "material-table";
import {Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText} from '@material-ui/core';
import {FormControl, InputLabel, Select, MenuItem, OutlinedInput} from '@material-ui/core';
import {TextField, Button} from '@material-ui/core';
import {GLOBAL} from "../usoGeneral/Global";
import { Typography, Grid } from "@material-ui/core";
import FileBase64 from 'react-file-base64';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {styles} from '../usoGeneral/estilos';
import UploadIcon from '../usoGeneral/UploadIcon.svg';

function Recurso(props) {

    const { classes } = props;

    const [recursos , setRecursos] = useState([]); //arreglo con informacion de los recursos
    const [evaluadores , setEvaluadores] = useState([]); //arreglo simple de evaluadores
    const [entidades , setEntidades] = useState([]); //arreglo simple con entidades

    const [text1, setText1] = useState(""); //String con texto de Textfield1
    const [text2, setText2] = useState(""); //String con texto de Textfield2
    const [archivo , setArchivo] = useState(""); //string con imagen de nuevo evaluador
    const [selrow , setSelRow] = useState({}); //Objeto con datos de fila seleccionada
    const [oldEvaluador , setOldEvaluador] = useState("");

    const [control , setControl] = useState(0); //hook de control para useEffect
    const [dialog1 , setDialog1] = useState(false); //hook de control de Dialog1
    const [dialog2 , setDialog2] = useState(false); //hook de control de Dialog2
    const [dialog3 , setDialog3] = useState(false); //hook de control de Dialog3
    const [dialog4 , setDialog4] = useState(false); //hook de control de Dialog3
    const [selEntidad , setSelEntidad] = useState(""); //controlador del combo de entidades
    const [selEvaluador , setSelEvaluador] = useState(""); //controlador del combo de evaluadores


    // Traer datos de evaluadores y entdades desde API
    const getData = () =>{
        axios 
        .get(GLOBAL+'registro?tipo=3')
        .then(response => {
            setRecursos(response.data);
        })
        .catch(error => {
            console.log(error);
        });

        axios 
        .get(GLOBAL+'registro?tipo=4')
        .then(response => {
            setEntidades(response.data);
        })
        .catch(error => {
            console.log(error);
        });

        axios 
        .get(GLOBAL+'registro?tipo=5')
        .then(response => {
            setEvaluadores(response.data);
        })
        .catch(error => {
            console.log(error);
        });

    };

    // Guardar nuevo recurso en base de datos
    const handlerAddRecurso = () =>{
        let entName, evaName;

        entidades.forEach(enti =>{ //Primero recuperamos el nombre de la entidad seleccionada
            if(enti._id === selEntidad){
              entName = enti.S_entNombre;
            }
        });

        evaluadores.forEach(eva =>{ //Primero recuperamos el nombre del evaluador
            if(eva._id === selEvaluador){
                evaName = eva.S_evaNombre;
            }
        });

        let body = {
            tipo: 3,
            nombre: text1,
            correo: text2,
            entidad: entName,
            idEntidad: selEntidad,
            evaluador: evaName,
            idEvaluador: selEvaluador,
            imagen: archivo
        };

        axios 
            .post(GLOBAL+'registro', body, {headers: {'Content-Type': 'application/json'}})
            .then(response => {
            handlerDialog1();
            })
            .catch(error => {
                alert(error);
            });


    };

    // Mandar cambios de registro a API
    const mandarCambios = () =>{

        let entName, evaName;

        entidades.forEach(enti =>{ //Primero recuperamos el nombre de la entidad seleccionada
            if(enti._id === selEntidad){
              entName = enti.S_entNombre;
            }
        });

        evaluadores.forEach(eva =>{ //Primero recuperamos el nombre del evaluador
            if(eva._id === selEvaluador){
                evaName = eva.S_evaNombre;
            }
        });

        let body = {
            tipo: 3,
            activo: true,
            id: selrow._id,
            nombre: text1,
            correo: text2,
            entidad: entName,
            idEntidad: selEntidad,
            evaluador: evaName,
            idEvaluador: selEvaluador,
            idOldEvaluador: oldEvaluador,
            imagen: archivo
        };
        console.log(body);
        axios
            .put(GLOBAL+'registro', body, {headers: {'Content-Type': 'application/json'}})
            .then(response => {
            handlerDialog3();
            setText1("");
            setText2("");
            setSelRow({});
            setSelEntidad("");
            setSelEvaluador("");
            setArchivo("");
            })
            .catch(error => {
                alert(error);
            });

    };

    // Cerrar Dialogo1 y limpiar hooks
    const handlerDialog1 = () =>{
        setText1("");
        setText2("");
        setArchivo("");
        setSelEntidad("");
        setSelEvaluador("");
        setDialog1(!dialog1);
    };

    // Cerrar Dialogo2 y limpiar hooks
    const handlerDialog2 = () =>{
        setDialog2(!dialog2);
    };

    // Cerrar Dialogo3 y limpiar hooks
    const handlerDialog3 = () =>{
        setDialog3(!dialog3);
    };

    // Controlar Dialog4
    const handlerDialog4 = () => {
        setDialog4(!dialog4);
    };

    // Guarda String de Textfield  en hook
    const handlerTextField1 = (e) =>{
        setText1(e.target.value);
    };

    // Guarda String de Textfield  en hook
    const handlerTextField2 = (e) =>{
        setText2(e.target.value);
    };

    //Controla selección del combo de entidades
    const handlerFormEnt = (e) =>{
        setSelEntidad(e.target.value);
    };

    //Controla selección del combo de evaluadores
    const handlerFormEva = (e) =>{
        setSelEvaluador(e.target.value);
    };

    //Guarda imagen tipo string en hook
    const handleImg = (e) => {
        setArchivo(e.base64);
    };

    // Controlador de Dialog antes de borrar registro
    const handlerBorrar = () => {
        handlerDialog3();
        handlerDialog4();
    };
  
    // Maneja los valores de las variables para edicion
    const editarRegistro = () => { 
        handlerDialog2();

        setText1(selrow.S_recNombre);
        setText2(selrow.S_recCorreo);
        setSelEntidad(selrow.I_recEntidad);
        setSelEvaluador(selrow.I_recEvaluador);
        setArchivo(selrow.S_recImagen);
        setOldEvaluador(selrow.I_recEvaluador);

        handlerDialog3();
        
    };

    // //Borrado lógico de registro con la API
    const borrarRegistro = () => {
        let body = {
        tipo: 3,
        activo: false,
        id: selrow._id,
        idEvaluador: selrow.I_recEvaluador
        };

        axios.put(GLOBAL+'registro', body, {headers: {'Content-Type': 'application/json'}})
        .then(response => {
        handlerDialog4();
        setText1("");
        setArchivo("");
        setSelRow({});
        })
        .catch(error => {console.log(error);});

    };

    useEffect(() => {
        if(control === 0){
            getData();
            setControl(1);
        }
    });

    return(
    <div>

        <MaterialTable  
                title=""
                columns={[
                // { title: <Typography variant="subtitle1">Imagen</Typography>  , field: 'S_recImagen', render: rowData => <img src={rowData.S_recImagen} alt="" style={{width: 30, height: 30, borderRadius: '50%'}}/> },
                { title: <Typography variant="subtitle1">Nombre</Typography> , field: 'S_recNombre' },
                { title: <Typography variant="subtitle1">Entidad</Typography> , field: 'S_recEntidad' },
                { title: <Typography variant="subtitle1">Correo</Typography> , field: 'S_recCorreo' }
                ]}
                data={recursos}
                options={{
                filtering: false,
                headerStyle: {
                    backgroundColor: '#ee7203',
                    color: '#fafffb'
                }
                }}
                actions={[
                {
                    icon: 'refresh',
                    tooltip: 'Recargar',
                    isFreeAction: true,
                    onClick: () => {getData()},
                },
                {
                    icon: 'add',
                    tooltip: 'Añadir recurso',
                    isFreeAction: true,
                    onClick: (event) => setDialog1(true)
                }
                ]}
                localization={{
                body:{
                    emptyDataSourceMessage: 'No hay registros por mostrar',
                    filterRow:{filterTooltip: 'Filtro'},
                editRow:{
                    deleteText: "¿Está seguro de borrar este registro?",
                    saveTooltip: "Guardar",
                    cancelTooltip: "Cancelar"
                }},
                header:{
                    actions: "Acciones"
                },
                pagination:{
                    labelDisplayedRows: '{from}-{to} de {count}',
                    labelRowsSelect: 'filas',
                    firstTooltip: 'Primera página',
                    previousTooltip: 'Página anterior',
                    nextTooltip: 'Página siguiente',
                    lastTooltip: 'Última página'
                },
                toolbar:{
                    searchTooltip: 'Buscar',
                    searchPlaceholder: 'Buscar'
                }
                }}
                onRowClick={((evt, selectedRow) => {
                setSelRow(selectedRow);
                handlerDialog2();
                })}
                
            />
            {/* Diálogo con los componentes para añadir nuevo registro */}
            <Dialog open={dialog1} onClose={handlerDialog1} maxWidth='sm' fullWidth={true}>

                <DialogTitle className={classes.SecondaryDark}>
                    <Typography align='center' variant="h5">Añadir nuevo recurso</Typography>
                </DialogTitle>

                <DialogContent>

                    <Grid container justify="center" alignItems="center" spacing={2}>

                    <Grid item>
                    <DialogContentText>
                        Por favor añada los datos necesarios del recurso.
                    </DialogContentText>
                    </Grid>

                    <Grid item xs={11}>
                    <TextField
                        autoFocus
                        id="name"
                        label="Nombre del recurso"
                        value={text1}
                        onChange={handlerTextField1}
                        margin="normal"
                        variant="outlined"
                        fullWidth={true}
                    />
                    </Grid>

                    <Grid item xs={11}>
                    <TextField
                        autoFocus
                        id="mail"
                        label="Correo del recurso"
                        value={text2}
                        onChange={handlerTextField2}
                        margin="normal"
                        variant="outlined"
                        fullWidth={true}
                    />
                    </Grid>
                    
                    <Grid item xs={11}>
                    <FormControl variant="outlined" fullWidth={true}>
                        <InputLabel>Entidad de trabajo</InputLabel>
                        <Select
                        value={selEntidad}
                        onChange={handlerFormEnt}
                        input={<OutlinedInput labelWidth={150} />}
                        >
                        {
                            entidades.map((data, index) => {
                                return(
                                    <MenuItem key={index} value={data._id} >{data.S_entNombre}</MenuItem>
                                )
                            })
                        }
                        </Select>
                    </FormControl>
                    </Grid>

                    <Grid item xs={11}>
                    {
                        selEntidad === "" ? 
                        <FormControl variant="outlined" fullWidth={true} disabled>
                            <InputLabel>Evaluador asignado</InputLabel>
                            <Select
                            value={selEvaluador}
                            onChange={handlerFormEva}
                            input={<OutlinedInput labelWidth={150} />}
                            >
                            </Select>
                        </FormControl> : <FormControl variant="outlined" fullWidth={true} > <InputLabel>Evaluador asignado</InputLabel>
                            <Select
                            value={selEvaluador}
                            onChange={handlerFormEva}
                            input={<OutlinedInput labelWidth={150} />}
                            >
                            {
                                evaluadores.map((data, index) => {
                                    return(
                                        data.I_evaEntidad === selEntidad ? <MenuItem key={index} value={data._id} >{data.S_evaNombre}</MenuItem> : false
                                    )
                                })
                            }
                            </Select>
                        </FormControl>}
                        </Grid>
                        
                    <Grid item>
                    <DialogContentText>
                        Foto del recurso.
                    </DialogContentText>
                    </Grid>

                    <div align='center'>
                        <img src={UploadIcon} className="uploadIcon" alt="Upload Icon" />
                    </div>

                    <Grid item>
                    <FileBase64
                        multiple={false}
                        onDone={handleImg}
                    />
                    </Grid>
                    <br />
                    <br />
                    <br />
                    <Grid item xs={10}>
                    <div align='center'>
                    {
                        archivo !== "" ? <img src={archivo} alt="Preview" className={classes.ImagenGrande} /> : true
                    }
                    </div>
                    </Grid>

                    </Grid>
                </DialogContent>

                <DialogActions>

                    <Button onClick={handlerDialog1} color="primary" className={classes.BotonPrincipal}>
                        Cancelar
                    </Button>

                    <Button onClick={handlerAddRecurso} color="primary" className={classes.BotonPrincipal}>
                        Añadir
                    </Button>

                </DialogActions>

            </Dialog>

            {/* Dialogo con vista de la informacion del registro */}
            <Dialog open={dialog2} onClose={handlerDialog2} maxWidth='sm' fullWidth={true}>

                <DialogTitle className={classes.SecondaryDark}>
                    <Typography align='center' variant="h5">{selrow.S_recNombre}</Typography>
                </DialogTitle>

                <DialogContent>
                    <Grid container justify="center" alignItems="center" spacing={2}>
                    <Grid item xs={10}>
                    <div align='center'>
                        <img src={selrow.S_recImagen} alt="" className={classes.ImagenGrande} />
                    </div>
                    </Grid>

                    <Grid item xs={11}>
                    <DialogContentText>
                        <Typography variant="h6" align='center'>{selrow.S_recEntidad}</Typography>
                        <Typography variant="h6" align='center'>{selrow.S_recCorreo}</Typography>
                        <Typography variant="h6" align='center'>Evaluador: {selrow.S_recEvaluador}</Typography>
                    </DialogContentText>
                    </Grid>

                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handlerDialog2} color="primary" className={classes.BotonPrincipal}>
                        Cerrar
                    </Button>
                    <Button onClick={editarRegistro} color="primary" className={classes.BotonPrincipal}>
                        Editar
                    </Button>
                </DialogActions>

            </Dialog>

            {/* Dialogo con campos para edicion de registros de recursos */}
            <Dialog open={dialog3} onClose={handlerDialog3} maxWidth='sm' fullWidth={true}>

                <DialogTitle className={classes.SecondaryDark}>
                    <Typography align='center' variant="h5">{selrow.S_recNombre}</Typography>
                </DialogTitle>

                <DialogContent>
    
                    <Grid container justify="center" alignItems="center" spacing={2}>
                     
                     <Grid item>
                    <DialogContentText>
                        Por favor modifique los datos necesarios del recurso.
                    </DialogContentText>
                    </Grid>

                    <Grid item xs={11}>
                    <TextField
                        autoFocus
                        id="name"
                        label="Nombre del recurso"
                        value={text1}
                        onChange={handlerTextField1}
                        margin="normal"
                        variant="outlined"
                        fullWidth={true}
                    />
                    </Grid>

                    <Grid item xs={11}>
                    <TextField
                        autoFocus
                        id="mail"
                        label="Correo del recurso"
                        value={text2}
                        onChange={handlerTextField2}
                        margin="normal"
                        variant="outlined"
                        fullWidth={true}
                    />
                    </Grid>
                    
                    <Grid item xs={11}>
                    <FormControl variant="outlined" fullWidth={true}>
                        <InputLabel>Entidad de trabajo</InputLabel>
                        <Select
                        value={selEntidad}
                        onChange={handlerFormEnt}
                        input={<OutlinedInput labelWidth={150} />}
                        >
                        {
                            entidades.map((data, index) => {
                                return(
                                    <MenuItem key={index} value={data._id} >{data.S_entNombre}</MenuItem>
                                )
                            })
                        }
                        </Select>
                    </FormControl>
                    </Grid>

                    <Grid item xs={11}>
                    <FormControl variant="outlined" fullWidth={true} > <InputLabel>Evaluador asignado</InputLabel>
                        <Select
                        value={selEvaluador}
                        onChange={handlerFormEva}
                        input={<OutlinedInput labelWidth={150} />}
                        >
                        {
                            evaluadores.map((data, index) => {
                                return(
                                    data.I_evaEntidad === selEntidad ? <MenuItem key={index} value={data._id} >{data.S_evaNombre}</MenuItem> : false
                                )
                            })
                        }
                        </Select>
                    </FormControl>
                    </Grid>

                    <div align='center'>
                        <img src={UploadIcon} className="uploadIcon" alt="Upload Icon" />
                    </div>

                    <Grid item>
                    <FileBase64
                        multiple={false}
                        onDone={handleImg}
                    />
                    </Grid>
                    <br />
                    <br />
                    <br />
                    <Grid item xs={10}>
                    <div align='center'>
                    {
                        archivo !== "" ? <img src={archivo} alt="Preview" className={classes.ImagenGrande} /> : true
                    }
                    </div>
                    </Grid>

                </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handlerDialog3} color="primary" className={classes.BotonPrincipal}>
                    Cancelar
                    </Button>

                    <Button onClick={handlerBorrar} color="primary" className={classes.BotonPrincipal}>
                    Borrar Recurso
                    </Button>

                    <Button onClick={mandarCambios} color="primary" className={classes.BotonPrincipal}>
                    Guardar cambios
                    </Button> 
                </DialogActions>

            </Dialog>

            {/* Dialog de advertencia antes del borrado lógico */}
            <Dialog open={dialog4} onClose={handlerDialog4} maxWidth='xs' fullWidth={true}>

                <DialogTitle className={classes.SecondaryDark}>
                    <Typography align='center' variant="h5">Borrar Recurso</Typography>
                </DialogTitle>

                <DialogContent>

                    <Grid container justify="center" alignItems="center" spacing={2}>

                    <Grid item>
                    <DialogContentText>
                        ¿Estás seguro de querer borrar el registro de {selrow.S_recNombre} ?
                    </DialogContentText>
                    </Grid>

                    </Grid>
                </DialogContent>

                <DialogActions>

                    <Button onClick={handlerDialog4} className={classes.BotonPrincipal}>
                        Cancelar
                    </Button>

                    <Button onClick={borrarRegistro} className={classes.BotonPrincipal}>
                        Borrar
                    </Button>

                </DialogActions>

            </Dialog>

    </div>);

}
Recurso.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  
  export default withStyles(styles)(Recurso);