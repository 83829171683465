import React, { useState, useEffect } from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Logo from './usoGeneral/logo.png';
import axios from "axios";
import Chip from '@material-ui/core/Chip';
import { green, blue, orange, red } from '@material-ui/core/colors';
import DoneIcon from '@material-ui/icons/Done';
import Schedule from '@material-ui/icons/Schedule';
import Subject from '@material-ui/icons/Subject';
import Clear from '@material-ui/icons/Clear';
import CanvasJSReact from './secundarios/canvasjs.react';
import {GLOBAL} from "./usoGeneral/Global";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Imprime(){

    const [usuario , setUsuario] = useState({
        nombre: "",
        correo: "",
        alta: Date,
        tecnologias: [{nombrecuestionario:"", respuestas:[],aciertos: 0}]
    });
    const [parametros , setParametros] = useState({});
    const [control , setControl] = useState(0);

    const fetchData = () =>{
      const params = new URL(window.location.href).searchParams;
        let pack ={
            mail: params.get('mail'),
            id: params.get('id'),
        };
        // console.log(pack);
        setParametros(pack);

            var dir = GLOBAL + 'aspirante?mail='+pack.mail;
            axios.get(dir).then(resp => {
                if(resp.data !== null){
                  setUsuario(resp.data);
                }else{
                  setUsuario({nombre:"Error de petición", correo:"petición vacía",
                tecnologias: [{nombrecuestionario:"", respuestas:[],aciertos: 0}]});
                } 
            });       
    };

    const filldata = (indice) =>{
    
        const respArray = usuario.tecnologias[indice].respuestas;
        var params = [];
        var cont=0;

        respArray.forEach(registro => {
          cont = 0;
          params.forEach(param => {
            if(registro.etiqueta.join(" y ").toString() === param.seccion){ //se encuentra registro con el mismo nombre de seccion
              cont += 1;
              param.totales += 1;
              if(registro.correcta === 1){
                param.arreglo[0].y += 1;
              }else{
                param.arreglo[1].y += 1;
              }
            }
          });
    
          if(cont === 0){// se crea nuevo registro si es la primera vez que se encuentra
            if(registro.correcta === 1){
              params.push({seccion: registro.etiqueta.join(" y ").toString(), arreglo: [{y: 1, label: "Aciertos"},{y: 0, label: "Errores"}], totales: 1});
            }else{
              params.push({seccion: registro.etiqueta.join(" y ").toString(), arreglo: [{y: 0, label: "Aciertos"},{y: 1, label: "Errores"}], totales: 1});
            }
          }
    
        });

        cont = 0;
    
        CanvasJS.addColorSet("CorrectaIncorrecta", [
          "#49b2d6",
          "#afafad"
        ]);
        
    
        return(
          <div>
            <br/>
              <br/>
        
            <Grid container spacing={3} justify="center">
              
            {params.map((graph, index) =>{
            return(
              <Grid item xs={11} sm={8} key={index}>
                <br/><br/>
              <CanvasJSChart 
              options={{
                exportEnabled: false,
                animationEnabled: false,
                colorSet: "CorrectaIncorrecta",
                title: {
                  text: graph.seccion + " - " + (graph.arreglo[0].y/graph.totales*10).toFixed(2)
                },
                data: [{
                  type: "doughnut",
                  startAngle: 180,
                  toolTipContent: "{y} <Typograhy variant=\"h5\" color=\"inherit\"> preguntas correctas</Typograhy>",
                  showInLegend: "false",
                  legendText: "{label}",
                  indexLabelFontSize: 16,
                  indexLabel: "{y} {label}",
                  dataPoints: graph.arreglo
              }]}}/>
          
          </Grid>
            )
          })}
    
            </Grid>
                
          </div>
        )
    
    };

    useEffect(() => {
      if(control === 0){
        fetchData();
        setControl(1);
      }
    }, []);

    return(
        <div>
            <AppBar position="fixed" color="default" style={{ backgroundColor: '#ee7203' }} >
          <Toolbar style={{ color: "#fafafa" }}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
              <Grid item>
                <img src={Logo} alt="Logo" width="40" height="40" />
              </Grid>
              <Grid item>
                <Typography variant="h5" color="inherit">
                  Resultados de la Prueba Técnica
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <br /><br /><br />
        
        <Grid container spacing={3} justify="center" >
          
{/*Solamente impresion de datos que se indican en URL*/}
          <Grid item xs={8}>
            <Card hidden={usuario.nombre === "" ? true : false}>

                <CardHeader
                title={usuario.nombre}
                subheader={usuario.correo} />

              </Card>
          </Grid>

          
              {
                  usuario.tecnologias.map((tech,index) =>{
                      if(tech._id===parametros.id){
                        return(
                          <Grid item xs={8} key={index}>
                              <Card hidden={usuario.nombre === "" ? true : false} >
                        <CardHeader
                        title={<Typography variant="h4">{tech.nombrecuestionario + " - "+tech.calificacion+": "+ (tech.califNumerica === undefined ? " " : tech.califNumerica)}</Typography>}
                        />
  
                        <CardContent>
  
                          <Grid container direction="row" spacing={2} justify="center">
                            <Grid item >
                              <Chip
                              icon={<Schedule/>}
                              label={"Tiempo para contestar: "+ tech.minutos +" minutos."}
                              color="primary"
                              style={{backgroundColor: blue[800]}}
                              />
                            </Grid>
                            <Grid item >
                              <Chip
                              icon={<Subject/>}
                              label={"Número de preguntas: " + tech.respuestas.length}
                              color="primary"
                              style={{backgroundColor: orange[600]}}
                              />
                            </Grid>
                            <Grid item >
                              <Chip
                              icon={<DoneIcon/>}
                              label={"Total de aciertos: "+ tech.aciertos}
                              color="primary"
                              style={{backgroundColor: green[500]}}
                              />
                            </Grid>
                            <Grid item >
                              <Chip
                              icon={<Clear/>}
                              label={"Total de errores: "+ (tech.respuestas.length - tech.aciertos)}
                              color="primary"
                              style={{backgroundColor: red[500]}}
                              />
                            </Grid>
                            
                          </Grid>
  
                          {/* Pie chart de las preguntas por etiqueta */}
                          {
                              filldata(index)
                          }
  
  
                        </CardContent>
                        
                        
  
                      </Card>
                    </Grid>
                        );
                      }else{
                        return(true)
                      }
                                            
                  })
              }
                    

        </Grid>
        </div>
    );
}
export default Imprime;