import React, { useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Logo from './usoGeneral/logo.png';
import { green, blue, amber, red } from '@material-ui/core/colors';
import 'typeface-roboto';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import LocationCity from '@material-ui/icons/LocationCity';
import HowToReg from '@material-ui/icons/HowToReg';
import People from '@material-ui/icons/People';
import Entidad from './secundarios/EntidadObjeto';
import Evaluador from './secundarios/EvaluadorObjeto';
import Recurso from './secundarios/RecursoObjeto';
import Burger from './Menu';
// import Swal from "sweetalert2";

const styles = theme => ({
    card: {
        minWidth: 275,
    },
    contenedor: {
        position: 'static',
        top: 100,
    },
    fabButton1: {
        position: 'absolute',
        top: 7,
        right: 200,
        margin: '0 auto',
        background: amber[700],
        "&:hover": {
            background: amber[500],
        }
    },
    fabButton11: {
        position: 'relative',
        margin: '0 auto',
        background: "#ee7203",
        "&:hover": {
            background: amber[500],
        }
    },
    fabButton2: {
        position: 'absolute',
        top: 7,
        right: 125,
        margin: '0 auto',
        background: amber[700],
        "&:hover": {
            background: blue[500],
        }
    },
    fabButton3: {
        position: 'absolute',
        top: 7,
        right: 50,
        margin: '0 auto',
        background: amber[700],
        "&:hover": {
            background: green[500],
        }
    },
    botonDeBorrado: {
        position: 'absolute',
        bottom: -20,
        left: 0,
        margin: '0 auto',
        background: red[500],
        "&:hover": {
            background: red[300],
        }
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    lista: {
        width: '100%',
        maxWidth: 400,
        maxHeight: 200,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
    },
    botonSend: {
        position: 'relative',
        margin: '0 auto',
        background: "#3aa832",
        "&:hover": {
            background: "#36b82c",
        }
    },
});

function Registro() {

    const [tabValor, setTabValor] = useState(0);

    const handleTab = (e, v) =>{
        setTabValor(v);
    };

    return(
        <div>
             <AppBar position="fixed" color="default" style={{ backgroundColor: '#ee7203' }} >
                <Toolbar style={{ color: "#fafafa" }}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                        <Grid item>
                            <img src={Logo} alt="Logo" width="40" height="40" />
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" color="inherit">
                                Registro
                            </Typography>
                        </Grid>
                    </Grid>
                    <Burger />
                </Toolbar>
            </AppBar>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <Grid container spacing={3} justify="center">

                <Grid item xs={8}>

                    <Card>

                        <CardContent>

                            <Tabs
                            value={tabValor}
                            onChange={handleTab}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            >

                                <Tab 
                                    label={<Typography variant="h6" color="inherit">Entidades</Typography>}
                                    icon={<LocationCity fontSize="large" />}
                                    // value={1} 
                                    />
                                <Tab 
                                    label={<Typography variant="h6" color="inherit">Evaluadores</Typography>}
                                    icon={<HowToReg fontSize="large"/>}
                                    // value={2} 
                                    />
                                <Tab 
                                    label={<Typography variant="h6" color="inherit">Recursos</Typography>}
                                    icon={<People fontSize="large"/>}
                                    // value={3} 
                                    />

                            </Tabs>

                            {tabValor===0 && <Entidad/>}
                            {tabValor===1 && <Evaluador/>}
                            {tabValor===2 && <Recurso />}
                            

                        </CardContent>

                    </Card>

                </Grid>

            </Grid>
        </div>
    );
}
Registro.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Registro);