import React  from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

function Resp({borrar}){

    return(
        <div>
                <IconButton aria-label="Delete" onClick={borrar} >
                <DeleteIcon fontSize="small" />
                </IconButton>
        </div>
    );
}

export default Resp;