import React, { Component } from 'react';
import './App.css';
// import Union from './components/options'; // could be deprecated
import Final from "./principales/Fin";
import Invitacion from "./principales/Invitacion";
import Examen from "./principales/Examen";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Resultados from "./principales/Resultados";
import Imprime from "./principales/Impresion";
import Menu from './principales/CuestionarioNuevo';
import Update from './principales/CuestionarioActualiza';
import Registro from './principales/Registro';
import Consulta from './principales/Consultas'; //TO DEVELOP
import Evaluacion from './principales/Evaluacion';


class App extends Component {
  
  render() {
    return (
      <div>
        <BrowserRouter>
              <Switch>
                <Route exact path='/evaluacion/nuevo'  component={Menu} />
                <Route exact path='/evaluacion/actualiza' component={Update} />
                <Route exact path='/evaluacion/registro'  component={Registro} />
                <Route exact path='/evaluacion/consultas' component={Consulta} />
                <Route exact path='/evaluacion/invitacion' component={Invitacion} />
                <Route exact path='/evaluacion/salir' component={Final} />
                <Route exact path='/evaluacion/impresion' component={Imprime} />
                <Route exact path='/evaluacion/examen' component={Examen} />
                <Route exact path='/evaluacion/cuestionario' component={Evaluacion} />
                <Route exact path='/evaluacion/resultados' component={Resultados}/>
              </Switch>
            </BrowserRouter>
    
      </div>
    );
  }
}

export default App;
