import React, {useState, useEffect} from "react";
import axios from 'axios';
import MaterialTable from "material-table";
import {Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText} from '@material-ui/core';
import {TextField, Button} from '@material-ui/core';
import {GLOBAL} from "../usoGeneral/Global";
import { Typography, Grid } from "@material-ui/core";
import FileBase64 from 'react-file-base64';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {styles} from '../usoGeneral/estilos';
import UploadIcon from '../usoGeneral/UploadIcon.svg';



function Entidad(props){

  const { classes } = props;

  const [entidades, setEntidades] = useState([]);
  const [control , setControl] = useState(0);
  const [selrow , setSelRow] = useState(0);
  const [dialog1 , setDialog1] = useState(false);
  const [dialog2 , setDialog2] = useState(false);
  const [dialog3 , setDialog3] = useState(false);
  const [dialog4 , setDialog4] = useState(false);

  const [text1 , setText1] = useState("");
  const [archivo , setArchivo] = useState("");

  // Traer datos de API
  const getData = () =>{
    axios 
    .get(GLOBAL+'registro?tipo=1')
    .then(response => {
        setEntidades(response.data);
        console.log(response);
        // console.log(entidades);
    })
    .catch(error => {
        console.log(error);
    });
  };

  // Funcion para cancelar y reiniciar valor original de las variables
  const cancelar = () =>{
    setText1("");
    setArchivo("");
    setDialog1(false);
    setDialog2(false);
    setDialog3(false);
    setDialog4(false);
  };

  // Cerrar Dialogo y limpiar hooks
  const handlerDialog1 = () =>{
    setText1("");
    setArchivo("");
    setDialog1(!dialog1);
  };

  // Controlar Dialog 2
  const handlerDialog2 = () => {
    setDialog2(!dialog2);
  };

  // Controlar Dialog3
  const handlerDialog3 = () => {
    setDialog3(!dialog3);
  };

  // Controlar Dialog4
  const handlerDialog4 = () => {
    setDialog4(!dialog4);
  };

  // Controlador para cambiar entre Dialogs
  const editarRegistro = () => {
    handlerDialog2();
    handlerDialog3();
    setText1(selrow.S_entNombre);
    setArchivo(selrow.S_entImagen);
  };

  // Recive datos para crear nuevo registro y manda a API
  const handlerAddEntidad = () => {
    let body = {
      tipo: 1,
      nombre: text1,
      imagen: archivo,
    };
    axios 
    .post(GLOBAL+'registro', body, {headers: {'Content-Type': 'application/json'}})
    .then(response => {
      handlerDialog1();
    })
    .catch(error => {
        alert(error);
    });
  };

  // Guarda String de Textfield  en hook
  const handlerTextField1 = (e) =>{
    setText1(e.target.value);
  };

  //Guarda imagen tipo string en hook
  const handleImg = (e) => {
    setArchivo(e.base64);
  };

  // Mandar cambios en el registro a BD
  const mandarCambios = () => {
    let body = {
      tipo: 1,
      activo: true,
      id: selrow._id,
      nombre: text1,
      imagen: archivo
    };

    axios.put(GLOBAL+'registro', body, {headers: {'Content-Type': 'application/json'}})
    .then(response => {
      handlerDialog3();
      setText1("");
      setArchivo("");
      setSelRow({});
    })
    .catch(error => {console.log(error);});

  };

  // Controlador de Dialog antes de borrar registro
  const handlerBorrar = () => {
    handlerDialog3();
    handlerDialog4();
  };

  // //Borrado lógico de registro con la API
  const borrarRegistro = () => {
    let body = {
      tipo: 1,
      activo: false,
      id: selrow._id
    };

    axios.put(GLOBAL+'registro', body, {headers: {'Content-Type': 'application/json'}})
    .then(response => {
      handlerDialog4();
      setText1("");
      setArchivo("");
      setSelRow({});
    })
    .catch(error => {console.log(error);});

  };


  useEffect(() => {
    if(control === 0){
      getData();
      setControl(1);
      // console.log(entidades)
    }
  });
  
  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable  
        title=""

        columns={[
          // { title: <Typography align="center" variant="subtitle1">ID</Typography>  , field: '_id' },
    { title: <Typography align="center" variant="subtitle1">Imagen</Typography>  , field: 'S_entImagen', render: rowData => <img src={rowData.S_entImagen} alt="" style={{width: 30, height: 30, borderRadius: '50%'}}/> },
          { title: <Typography align="center" variant="subtitle1">Nombre</Typography> , field: 'S_entNombre' },
        ]}

        data={entidades}

        options={{
          filtering: false,
          headerStyle: {
            backgroundColor: '#ee7203',
            color: '#fafffb'
          }
        }}

        actions={[
          {
            icon: 'refresh',
            tooltip: 'Recargar',
            isFreeAction: true,
            onClick: () => {getData()},
          },
          {
            icon: 'add',
            tooltip: 'Añadir entidad',
            isFreeAction: true,
            onClick: (event) => setDialog1(true)
          }
        ]}

        localization={{
          body:{
            emptyDataSourceMessage: 'No hay registros por mostrar',
            filterRow:{filterTooltip: 'Filtro'},
          editRow:{
            deleteText: "¿Está seguro de borrar este registro?",
            saveTooltip: "Guardar",
            cancelTooltip: "Cancelar"
          }},
          header:{
            actions: "Acciones"
          },
          pagination:{
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'filas',
            firstTooltip: 'Primera página',
            previousTooltip: 'Página anterior',
            nextTooltip: 'Página siguiente',
            lastTooltip: 'Última página'
          },
          toolbar:{
            searchTooltip: 'Buscar',
            searchPlaceholder: 'Buscar'
          }
        }}

        onRowClick={((evt, selectedRow) => {
          setSelRow(selectedRow);
          handlerDialog2();
        })}

        
      />
      {/* Dialogo para crear un nuevo registro de entidad */}
      <Dialog open={dialog1} onClose={handlerDialog1} maxWidth='sm' fullWidth={true} >

          <DialogTitle className={classes.SecondaryDark}>
            <Typography align='center' variant="h5">Añadir nueva entidad</Typography>
          </DialogTitle>

          <DialogContent>

          <Grid container justify="center" alignItems="center" spacing={2}>

          <Grid item>
            <DialogContentText>
              Por favor añada los datos necesarios de las entidades.
            </DialogContentText>
          </Grid>

          <Grid item xs={11}>
            <TextField
              autoFocus
              margin="normal"
              variant="outlined"
              fullWidth={true}
              id="name"
              label="Nombre de la entidad de trabajo"
              value={text1}
              onChange={handlerTextField1}
            />
          </Grid>

          <div align='center'>
            <img src={UploadIcon} className="uploadIcon" alt="Upload Icon" />
          </div>

          <Grid item>
            
            <FileBase64
              multiple={false}
              onDone={handleImg}
            />
          </Grid>
            
            <Grid item xs={10}>
              <div align='center'>
              {
                archivo !== "" ? <img src={archivo} alt="Preview" className={classes.ImagenGrande} /> : true
              }
              </div>
            </Grid>

            </Grid>

          </DialogContent>

        

        <DialogActions>

          <Button onClick={cancelar} color="primary" className={classes.BotonPrincipal}>
            Cancelar
          </Button>

          <Button onClick={handlerAddEntidad} color="primary" className={classes.BotonPrincipal}>
            Añadir
          </Button>

        </DialogActions>

      </Dialog>
    
      {/* Dialogo para visualizar informacion completa de registro */}
      <Dialog open={dialog2} onClose={handlerDialog2} maxWidth='sm' fullWidth={true} >

        <DialogTitle className={classes.SecondaryDark} >
            <Typography align='center' variant="h5">{selrow.S_entNombre}</Typography>
        </DialogTitle>

        <DialogContent>
        <Grid container justify="center" alignItems="center" spacing={2}>
            
            <Grid item xs={10}>
            <div align="center">
                <img src={selrow.S_entImagen} alt="" className={classes.ImagenGrande}/>
            </div>
            </Grid>

            <Grid item>
            <DialogContentText>
                <Typography  variant="h6">Contacto</Typography>
                <Typography  variant="h6">Dirección</Typography>
            </DialogContentText>
            </Grid>
            
            </Grid>
        </DialogContent>

        

        <DialogActions>

          <Button onClick={cancelar} color="primary" className={classes.BotonPrincipal}>
              Cerrar
          </Button>
          
          <Button onClick={editarRegistro} color="primary" className={classes.BotonPrincipal}>
              Editar
          </Button>
         
        </DialogActions>

      </Dialog>

      {/* Dialog con campos para editar informacion */}
      <Dialog open={dialog3} onClose={handlerDialog3} maxWidth='sm' fullWidth={true}>

      <DialogTitle className={classes.SecondaryDark}>
            <Typography align='center' variant="h5">{selrow.S_entNombre}</Typography>
        </DialogTitle>

        <DialogContent>

          <Grid container justify="center" alignItems="center" spacing={2}>

          <Grid item>
          <DialogContentText>
            Por favor modifique los datos necesarios de la entidad.
          </DialogContentText>
          </Grid>

          <Grid item xs={11}>
          <TextField
            autoFocus
            margin="normal"
            variant="outlined"
            fullWidth={true}
            id="name"
            label="Nombre de la entidad de trabajo"
            value={text1}
            onChange={handlerTextField1}
          />
          </Grid>
          
          <div align='center'>
            <img src={UploadIcon} className="uploadIcon" alt="Upload Icon" />
          </div>

          <Grid item>
          <FileBase64
            multiple={false}
            onDone={handleImg}
          />
          </Grid>
          <br />
          <br />
          <br />
          <Grid item xs={10}>
          <div align='center'>
          {
            archivo !== "" ? <img src={archivo} alt="Preview" width="50%" className={classes.ImagenGrande}/> : true
          }
          </div>
          </Grid>

          </Grid>
            
        </DialogContent>

        <DialogActions>
            <Button onClick={cancelar} color="primary" className={classes.BotonPrincipal}>
                Cancelar
            </Button>

            <Button onClick={handlerBorrar} color="primary" className={classes.BotonPrincipal}>
              Borrar Entidad
            </Button>

            <Button onClick={mandarCambios} color="primary" className={classes.BotonPrincipal}>
                Guardar cambios
            </Button>

        </DialogActions>

      </Dialog>
      
      {/* Dialog de advertencia antes del borrado lógico */}
      <Dialog open={dialog4} onClose={handlerDialog4} maxWidth='xs' fullWidth={true}>

        <DialogTitle className={classes.SecondaryDark}>
          <Typography  variant="h5">Borrar Entidad</Typography>
        </DialogTitle>

        <DialogContent>

          <Grid container  justify="center" alignItems="center" spacing={2}>

          <Grid item>
          <DialogContentText>
            ¿Estás seguro de querer borrar el registro de {selrow.S_entNombre} ?
          </DialogContentText>
          </Grid>

          </Grid>
        </DialogContent>

        <DialogActions>

          <Button onClick={cancelar} className={classes.BotonPrincipal}>
            Cancelar
          </Button>

          <Button onClick={borrarRegistro} className={classes.BotonPrincipal}>
            Borrar
          </Button>

        </DialogActions>

      </Dialog>
    
    </div>
  );
  
}
Entidad.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Entidad);
