import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Logo from "./usoGeneral/logo.png";
import Burger from "./Menu";
import Downshift from "downshift";
import axios from "axios";
import Chip from "@material-ui/core/Chip";
import { green, blue, orange, red } from "@material-ui/core/colors";
import DoneIcon from "@material-ui/icons/Done";
import Clear from "@material-ui/icons/Clear";
import Schedule from "@material-ui/icons/Schedule";
import Subject from "@material-ui/icons/Subject";
import CanvasJSReact from "./secundarios/canvasjs.react";
import { GLOBAL } from "./usoGeneral/Global";
import ExportPdf from "./exportPdf/ExportPdf";
import LinearProgress from "@material-ui/core/LinearProgress"

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
const levelLabel = {
  1: "Nivel Básico",
  2: "Nivel Medio",
  3: "Nivel Avanzado"
};

class Resultados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mails: [],
      user: {
        nombre: "",
        correo: "",
        alta: Date,
        tecnologias: []
      },
      graphCorrectas: [],
      graphIncorrectas: []
    };

    this.fetchMails = this.fetchMails.bind(this);
    this.inputOnChange = this.inputOnChange.bind(this);
    this.downshiftOnChange = this.downshiftOnChange.bind(this);
    this.filldata = this.filldata.bind(this);
  }

  inputOnChange(event) {
    if (!event.target.value) {
      return;
    }
    this.fetchMails(event.target.value);
  }

  //Obtiene lista de correos en BD
  fetchMails() {
    const mailURL = GLOBAL + `correos`;
    axios.get(mailURL).then(response => {
      this.setState({ mails: response.data });
    });
  }

  //Pide información sobre el candidato a partir de su email
  downshiftOnChange(selectedItem) {
    const resultsURL = GLOBAL + `aspirante?mail=` + selectedItem.correo;
    //    console.log(resultsURL);
    axios.get(resultsURL).then(resp => {
      // console.log(resp.data);
      this.setState({
        user: {
          nombre: resp.data.nombre,
          correo: resp.data.correo,
          alta: resp.data.alta,
          tecnologias: resp.data.tecnologias
        }
      });
      //this.setState({ user: resp.data });
    });
  }

  filldata(indice) {
    const respArray = this.state.user.tecnologias[indice].respuestas;
    // var correctas = this.state.graphCorrectas;
    // var incorrectas = this.state.graphIncorrectas;
    let params = [];
    var cont = 0;
    // var cont2 =0;

    // correctas= [];
    // incorrectas = [];

    //Realizar barrido de respuestas y crear arreglo con arreglos de objetos para graficar
    // [0]={seccion: "Nombre de la seccion", arreglo: [{y: 0, label: "Aciertos"},{y: 0, label: "Errores"}], totales: 0 }
    // Primero crear tantos elementos como hay etiquetas y hacer PUSH del formato
    // Clasificar en errores y aciertos por seccion y añadir a part correspondiente

    respArray.forEach(registro => {
      cont = 0;
      params.forEach(param => {
        if (registro.etiqueta.join(" y ").toString() === param.seccion) {
          //se encuentra registro con el mismo nombre de seccion
          cont += 1;
          param.totales += 1;
          if (registro.correcta === 1) {
            param.arreglo[0].y += 1;
          } else {
            param.arreglo[1].y += 1;
          }
        }
      });

      if (cont === 0) {
        // se crea nuevo registro si es la primera vez que se encuentra
        if (registro.correcta === 1) {
          params.push({
            seccion: registro.etiqueta.join(" y ").toString(),
            arreglo: [
              { y: 1, label: "Aciertos" },
              { y: 0, label: "Errores" }
            ],
            totales: 1
          });
        } else {
          params.push({
            seccion: registro.etiqueta.join(" y ").toString(),
            arreglo: [
              { y: 0, label: "Aciertos" },
              { y: 1, label: "Errores" }
            ],
            totales: 1
          });
        }
      }
    });

    // console.log(params);

    cont = 0;

    // respArray.forEach(element => {

    //   if(element.correcta ===1){
    //     //si la respuesta es correcta se hace comparación en arreglo de data para agregar a la etiqueta correcta
    //       correctas.forEach(elem => {
    //         if(element.etiqueta.join(" y ").toString() === elem.label ){
    //           elem.y += 1;
    //           cont +=1;
    //         }
    //       });

    //       if(cont === 0){
    //         correctas.push({y: 1, label: element.etiqueta.join(" y ").toString()});
    //       }

    //   }else{
    //       incorrectas.forEach(objt => {
    //         if( element.etiqueta.join(" y ").toString() === objt.label ){
    //           objt.y += 1;
    //           cont2 +=1;
    //         }
    //       });

    //       if(cont2 === 0){
    //         incorrectas.push({y:1, label: element.etiqueta.join(" y ").toString() });
    //       }
    //   }

    // });

    // CanvasJS.addColorSet("correctColors",
    // 	[//colorSet Array
    //     "#006747",
    //     "#009639",
    //     "#78be20",
    //     "#00a499",
    //     "#404e4d"
    // ]);

    // CanvasJS.addColorSet("incorrectColors",
    // 	[//colorSet Array
    //     "#8a1538",
    //     "#da291c",
    //     "#ed8b00",
    //     "#ffb81c",
    //     "#fae100"
    // ]);

    CanvasJS.addColorSet("CorrectaIncorrecta", ["#49b2d6", "#afafad"]);

    return (
      <div>
        <br />
        <br />

        <Grid container spacing={3} justify="center">
          {params.map((graph, index) => {
            return (
              <Grid item xs={11} key={index}>
                <CanvasJSChart
                  options={{
                    exportEnabled: false,
                    animationEnabled: false,
                    colorSet: "CorrectaIncorrecta",
                    title: {
                      text:
                        graph.seccion +
                        " - " +
                        ((graph.arreglo[0].y / graph.totales) * 10).toFixed(2)
                    },
                    data: [
                      {
                        type: "doughnut",
                        startAngle: 180,
                        toolTipContent:
                          '{y} <Typograhy variant="h5" color="inherit"> preguntas correctas</Typograhy>',
                        showInLegend: "false",
                        legendText: "{label}",
                        indexLabelFontSize: 16,
                        indexLabel: "{y} {label}",
                        dataPoints: graph.arreglo
                      }
                    ]
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }

  levelIndicator = (levelArr) => {
    if(levelArr.length === 0){
      return <Grid item xs={12}>
              <Typography variant="h5" color="inherit"> Este cuestionario no tiene preguntas de nivel. </Typography>
            </Grid>
    }
      return <Grid item xs={12}>
              <Typography variant="h4" color="inherit"> Clasificación por nivel: </Typography>
              <br/>
              <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
              {
                levelArr.map((elem)=>{
                  if(elem.total > 0){
                    return <><Grid item xs={6} lg={3}> <Typography variant="h6">{levelLabel[elem.nivel]+" ("+elem.aciertos+"/"+elem.total+")"}</Typography> </Grid>
                        <Grid item xs={12} lg={9}> <LinearProgress variant="determinate" value={elem.aciertos/elem.total * 100} /> </Grid></>
                  }
                  return null;
                })
              }
              </Grid>
            </Grid>
    
  }

  render() {
    const PdfLink = this.state.user.correo !=="" ? (
      <Grid
        container
        alignItems="flex-start"
        justify="flex-end"
        direction="row"
      >
        <ExportPdf data={this.state.user} />
      </Grid>
    ) : (
      ""
    );
    return (
      <div>
        <AppBar
          position="fixed"
          color="default"
          style={{ backgroundColor: "#ee7203" }}
        >
          <Toolbar style={{ color: "#fafafa" }}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <img src={Logo} alt="Logo" width="40" height="40" />
              </Grid>
              <Grid item>
                <Typography variant="h5" color="inherit">
                  Resultados
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Burger />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Grid container spacing={3} justify="center">
          <Grid item xs={8}>
            <Card>
              <CardContent>
                <Typography variant="h5" color="inherit">
                  Buscar Candidato
                </Typography>
                <Downshift
                  onChange={this.downshiftOnChange}
                  itemToString={item => (item ? item.correo : "")}
                >
                  {({
                    selectedItem,
                    getInputProps,
                    getItemProps,
                    highlightedIndex,
                    isOpen,
                    inputValue
                  }) => (
                    <div>
                      <TextField
                        {...getInputProps({
                          onChange: this.inputOnChange
                        })}
                        label="Email del candidato"
                        margin="normal"
                        variant="outlined"
                        fullWidth={true}
                      />
                      {isOpen ? (
                        <div className="downshift-dropdown">
                          {this.state.mails
                            .filter(
                              item =>
                                !inputValue ||
                                item.correo
                                  .toLowerCase()
                                  .includes(inputValue.toLowerCase())
                            )
                            .slice(0, 10)
                            .map((item, index) => (
                              <div
                                className="dropdown-item"
                                {...getItemProps({ key: index, index, item })}
                                style={{
                                  backgroundColor:
                                    highlightedIndex === index
                                      ? "lightgray"
                                      : "white",
                                  fontWeight:
                                    selectedItem === item ? "bold" : "normal"
                                }}
                              >
                                {item.correo}
                              </div>
                            ))}
                        </div>
                      ) : null}
                    </div>
                  )}
                </Downshift>
                {PdfLink}
              </CardContent>
            </Card>
          </Grid>
          {/*cambio Alfonso*/}
          <Grid item xs={8}>
            <Card hidden={this.state.user.nombre === "" ? true : false}>
              <CardHeader
                title={this.state.user.nombre}
                subheader={this.state.user.correo}
              />
            </Card>
          </Grid>

          {this.state.user.tecnologias.map((tech, index) => {
            return (
              <Grid item xs={8} key={index}>
                <Card>
                  <CardHeader
                    title={
                      <Typography variant="h4">
                        {tech.nombrecuestionario +
                          " - " +
                          tech.calificacion +
                          ": " +
                          (tech.califNumerica === undefined
                            ? " "
                            : tech.califNumerica)}
                      </Typography>
                    }
                  />

                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      justify="center"
                    >
                      <Grid item>
                        <Chip
                          icon={<Schedule />}
                          label={
                            "Tiempo para contestar: " +
                            tech.minutos +
                            " minutos."
                          }
                          color="primary"
                          style={{ backgroundColor: blue[800] }}
                        />
                      </Grid>
                      <Grid item>
                        <Chip
                          icon={<Subject />}
                          label={
                            "Número de preguntas: " + tech.respuestas.length
                          }
                          color="primary"
                          style={{ backgroundColor: orange[600] }}
                        />
                      </Grid>
                      <Grid item>
                        <Chip
                          icon={<DoneIcon />}
                          label={"Total de aciertos: " + tech.aciertos}
                          color="primary"
                          style={{ backgroundColor: green[500] }}
                        />
                      </Grid>
                      <Grid item>
                        <Chip
                          icon={<Clear />}
                          label={
                            "Total de errores: " +
                            (tech.respuestas.length - tech.aciertos)
                          }
                          color="primary"
                          style={{ backgroundColor: red[500] }}
                        />
                      </Grid>
                    </Grid>

                    <br/>
              
                      {
                        this.levelIndicator(tech.niveles)
                        // Función que regresa las barras de preguntas clasificadas por niveles
                      }
                  
                    {/* Pie chart de las preguntas por etiqueta */}
                    {this.filldata(index)}
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
}

export default Resultados;
