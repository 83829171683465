import React from "react";

import PdfDocument from "./pdfDocument/PdfDocument";
import { PDFDownloadLink } from "@react-pdf/renderer";

const ExportPdf = props => {
  const onPrepareDataHandler = data => {
    let name = data.nombre;
    let email = data.correo;

    let tecnologias = [];
    data.tecnologias.forEach(tec => {
      let nombreCuestionario = "Examen";
      let totalPreguntas = null;
      let contestadas = null;
      let correctas = 0;
      let incorrectas = 0;
      let calificacion = null;
      let respuestas = [];

      if (tec.respuestas && tec.respuestas.length > 0) {
        nombreCuestionario = tec.nombrecuestionario;
        totalPreguntas = tec.respuestas.length;
        contestadas = tec.contestadas;
        respuestas.push(tec.respuestas);

        tec.respuestas.forEach(res => {
          if (res.correcta === 1) {
            correctas += 1;
          } else if (res.correcta === 0) {
            incorrectas += 1;
          }
        });

        calificacion = tec.calificacion;
      }
      tecnologias.push({
        nombreCuestionario: nombreCuestionario,
        totalPreguntas: totalPreguntas,
        contestadas: contestadas,
        incorrectas: incorrectas,
        correctas: correctas,
        calificacion: calificacion,
        respuestas: respuestas
      });
    });

    const resultData = {
      name: name,
      email: email,
      tecnologias: tecnologias
    };

    return resultData;
  };

  return (
    <div>
      <PDFDownloadLink
        document={<PdfDocument data={onPrepareDataHandler(props.data)} />}
      >
        Export Pdf
      </PDFDownloadLink>
    </div>
  );
};

export default ExportPdf;
