import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { RadioGroup, ListItemText } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Chip from '@material-ui/core/Chip';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import grey from "@material-ui/core/colors/grey";

import Resp from './Respuestas';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { red } from "@material-ui/core/colors";
import Clear from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
//import ImageUploader from 'react-images-upload';
import FileBase64 from 'react-file-base64';
import UploadIcon from '../usoGeneral/UploadIcon.svg';
import LocalOffer from '@material-ui/icons/LocalOffer';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';


function Preg({ num, check, check1, check2, check3, check4, check5, reg_preg, reg_cod, reg_resp, question, code, answers, borra, recibeimg, etiquetas, tag, reg_tag, level, reg_level }) {

  const [pregunta, setPregunta] = useState(question);
  const [codB, setCodB] = useState(false); 
  const [imgB, setImgB] = useState(false)
  const [codigo, setCodigo] = useState(code);
  const [respuesta, setRespuesta] = useState("");
  const [respArray, setRespArray] = useState(answers);
  const [correct, setCorrect] = useState("");
  const [titulo, setTitulo] = useState(question === "" ? "Pregunta #" + num : num + ". " + question);
  const [archivo, setArchivo] = useState("");
  const [open, setOpen] = useState(false);
  const [taged , setTaged] = useState(tag);
  const [opcEtiq, setOpcEtiq]=useState(false);
  const [etiqSelec , setEtiqSelec] = useState([]);
  const [auxer , setAuxer] = useState([]);
  const [nivel , setNivel] = useState(level);
  const nivelPreguta = [{tipo:1, class: "Nivel Básico"},{tipo: 2, class: "Nivel Medio"},{tipo: 3, class: "Nivel Avanzado"}];


  const anchorEl = React.useRef(null);

  const addCode = (e) => {
    setCodigo(e.target.value);
    reg_cod(e.target.value);
  };

  const addResp = () => {
    if (respuesta === "") { return; } else {

      const copiaResp = Object.assign([], respArray);
      copiaResp.push({
        respuesta: respuesta,
        correcta: 0,
      });
      setRespArray(copiaResp);
      setRespuesta("");
      reg_resp(copiaResp);

    }
  };

  const assignTag = (index) =>{
    let i;
    const auxi = etiqSelec;
    const auxo = [];
    auxi[index].state = !etiqSelec[index].state;

    for(i=0; i< auxi.length; i++){
      if(auxi[i].state === true){
        auxo.push(auxi[i].nom);
      }
    }

    setTaged(auxo);
    reg_tag(auxo);
    setEtiqSelec(auxi);
    setOpen(!open);
  };

  const deleteEvent = (index) => {
    const copy = Object.assign([], respArray);
    copy.splice(index, 1);
    setRespArray(copy);
    reg_resp(copy);
  };

  const handleSwitch = () => {
    if (codB === false) {
      setCodB(true);
      setCodigo("");
      reg_cod("");
    } else {
      setCodB(false);
      setCodigo("0");
      reg_cod("0");
    }
  };

  const handleSwitch2 = () => {
    if (imgB === false) {
      setImgB(true);
      recibeimg("");
      setArchivo("");
    } else {
      setImgB(false);
      recibeimg("0");
      setArchivo("");
    }
  };

  const rightAnswer = (e) => {
    setCorrect(e.target.value);

    const copia = Object.assign([], respArray);
    var i, n;
    n = copia.length;
    for (i = 0; i < n; i++) {
      if (copia[i].respuesta === e.target.value) {
        copia[i].correcta = 1;
      } else {
        copia[i].correcta = 0;
      }
    }

    setRespArray(copia);
    reg_resp(copia);
  };

  const setPreg = (e) => {
    var subtitle = e.target.value;

    if (subtitle === "") {
      setPregunta("");
      setTitulo("Pregunta #" + num);
    } else {
      if (subtitle.length > 50) {
        setPregunta(subtitle);
        setTitulo(num + ". " + subtitle.substring(0, 50) + "...");
      } else {
        setPregunta(subtitle);
        setTitulo(num + ". " + subtitle.substring(0, 50));
      }
      reg_preg(subtitle);
    }

  };

  const setResp = (e) => {
    setRespuesta(e.target.value);
  };

  const handleImg = (e) => {
    setArchivo(e.base64);
    recibeimg(e.base64);
  };

  const handleLevel = (e) => {
    setNivel(e.target.value);
    reg_level(e.target.value);
  };

  function handleToggle() {
      // setOpen(!open);
      setOpcEtiq(true);
  }

  function handleClose(event) {
    // if (anchorEl.current.contains(event.target)) {
    //   return;
    // }

    // setOpen(false);
    setOpcEtiq(false);
  }

  useEffect(()=>{ //sirve para crear controlador de los checkbox de etiqutas ERROR
    setAuxer([]);
    let i,j,k;
    const n = etiquetas.length;
    const m = etiqSelec.length;
    const ccaux = etiqSelec; //arreglo con estados actuales
    const auxetq = auxer;//arreglo vacio de apoyo
    let nombre = ""; //variable con nombre auxiliar

    for(i=0; i<n; i++){//guarda en array vacío los objetos [etiqueta|estado]
      auxetq.push({
        nom: etiquetas[i],
        state: false
      });
    }
    
    for(j=0; j<m; j++){
      nombre = ccaux[j].nom;
      for(k=0; k<n; k++){
        if(nombre === auxetq[k].nom ){
          auxetq[k].state = ccaux[j].state;
        }
      }
    }

    setEtiqSelec(auxetq);

  },[etiquetas]);

  useEffect(()=>{
    let i;
    const copy = [];

    for(i=0; i<etiqSelec.length; i++){
      if(etiqSelec[i].state === true){
        copy.push(etiqSelec[i].nom);
      }
    }

    setTaged(copy);
    reg_tag(copy);

  },[etiqSelec]);

  useEffect(()=>{
    setTitulo(pregunta === "" ? "Pregunta #" + num : num + ". " + pregunta);
  },[num]);

  return (
    <div>
    <div>
      <Card >
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: grey[300] }}>
            <Grid container
              direction="row"
              justify="flex-start"
              alignItems="center">

              <Tooltip TransitionComponent={Zoom} title="Borrar pregunta">    
              <IconButton aria-label="Delete" onClick={borra}>
                <DeleteIcon fontSize="large" />
              </IconButton>
              </Tooltip>

              {/* Elemento con el texto de la pregunta y la sección a la que corresponde */}
              <ListItemText primary={<Typography variant="h6" color="inherit">{titulo}</Typography>} secondary={taged.lenght !== 0 ? <Typography variant="subtitle2" style={{color: '#ee7203'}}>{taged.join("   |   ")}</Typography> : "" } />
              

            </Grid>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails>
            <CardContent>
              <Grid container spacing={2} alignItems="center">

                <Grid item xs={11}>
                  <TextField
                    label="Pregunta"
                    margin="normal"
                    variant="outlined"
                    fullWidth={true}
                    // multiline={true}
                    value={pregunta}
                    onChange={setPreg}
                  />
                </Grid>

                <Grid item xs={1}>
                  <Tooltip TransitionComponent={Zoom} title="Añadir etiqueta.">
                  <IconButton
                    buttonRef={anchorEl}
                    aria-owns={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}>
                    {
                      taged.length !== 0 ? <LocalOffer fontSize="large" style={{color: '#ee7203'}} /> : <LocalOffer fontSize="large" />
                    }
                    
                  </IconButton>
                  </Tooltip>
                  
                </Grid>


                <Grid item xs={12} hidden={check2} >
                  <Chip
                    icon={<Clear />}
                    label="Debes escribir una pregunta. "
                    color="secondary"
                    style={{
                      position: 'relative',
                      top: 0,
                      left: 10,
                      background: red[500],
                      color: grey[50],
                    }}
                  />
                </Grid>

                <Grid item xs={12} hidden={check5} >
                  <Chip
                    icon={<Clear />}
                    label="Debes añadir una etiqueta a tu pregunta."
                    color="secondary"
                    style={{
                      position: 'relative',
                      top: 0,
                      left: 10,
                      background: red[500],
                      color: grey[50],
                    }}
                  />
                </Grid>


                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={codB}
                        color="primary"
                        value={codB}
                        onChange={handleSwitch}
                        style={{ color: '#ee7203' }}
                      />
                    }
                    label={codB ? "Con Código" : "Sin Código"}
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={imgB}
                        color="primary"
                        value={imgB}
                        onChange={handleSwitch2}
                        style={{ color: '#ee7203' }}
                      />
                    }
                    label={imgB ? "Con Imagen" : "Sin Imagen"}
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormControl variant="outlined" fullWidth={true} >
                      <InputLabel>Nivel</InputLabel>
                      <Select
                      input={<OutlinedInput labelWidth={70} />}
                      value={nivel}
                      onChange={handleLevel}
                      >
                      {
                          nivelPreguta.map((data, index) => {
                              return(
                                  <MenuItem key={index} value={data.tipo}>{data.class}</MenuItem>
                              )
                          })
                      }
                      </Select>
                  </FormControl>
                </Grid>


                <Grid item xs={12} hidden={codB ? false : true}>
                  <TextField
                    label="Código"
                    multiline
                    margin="normal"
                    variant="outlined"
                    fullWidth={true}
                    value={codigo}
                    onChange={addCode}
                  />
                </Grid>

                <Grid item xs={12} hidden={check3} >
                  <Chip
                    icon={<Clear />}
                    label="Debes añadir código en esta sección. "
                    color="secondary"
                    style={{
                      position: 'relative',
                      top: 0,
                      left: 10,
                      background: red[500],
                      color: grey[50],
                    }}
                  />
                </Grid>

                <Grid item xs={12} hidden={imgB ? false : true}>
                  <div align="center">
                    <img src={UploadIcon} className="uploadIcon" alt="Upload Icon" />
                    <br />
                    <FileBase64
                      multiple={false}
                      onDone={handleImg}
                    />
                    <br />
                    <br />
                    <br />
                    {
                      archivo !== "" ? <img src={archivo} alt="Preview" width="50%" /> : true
                    }
                  </div>

                  {/* <ImageUploader
                    withIcon={true}
                    buttonText='Subir Imagen.'
                    withLabel={true}
                    label="Tamaño máximo 5Mb, acepta: jpg, png y gif"
                    onChange={recibeimg}
                    imgExtension={['.jpg', '.gif', '.png', '.gif']}
                    maxFileSize={1048576} //en bytes binary
                    withPreview={true}
                    fileSizeError="La imagen es demasiado grande."
                    fileTypeError="No se aceptan imágenes con esa extensión."
                    buttonStyles={{background: '#ee7203'}}
                    singleImage={true} //Solamente permite una imagen
                    /> */}

                </Grid>

                <Grid item xs={12} hidden={check4} >
                  <Chip
                    icon={<Clear />}
                    label="Debes seleccionar una imágen en esta sección. "
                    color="secondary"
                    style={{
                      position: 'relative',
                      top: 0,
                      left: 10,
                      background: red[500],
                      color: grey[50],
                    }}
                  />
                </Grid>


                {/* <Grid item xs={12} >
                  <Typography variant="h6" color="inherit" jdisplay="inline">
                    Respuestas
                </Typography>
                </Grid> */}


                <Grid item xs={11}>
                  <TextField
                    label="Respuesta"
                    value={respuesta}
                    onChange={setResp}
                    margin="normal"
                    variant="outlined"
                    fullWidth={true}

                  />
                </Grid>


                <Grid item xs={1}>

                  <Tooltip TransitionComponent={Zoom} title="Añadir Respuesta">
                    <Fab size="large" color="secondary" style={{ position: 'relative', top: 0, right: 0, margin: '0 auto', background: '#ee7203' }} onClick={addResp}>
                      <AddIcon />
                    </Fab>
                  </Tooltip>

                </Grid>

                <Grid item xs={12} hidden={check1} >
                  <Chip
                    icon={<Clear />}
                    label="Debes añadir al menos 2 respuestas. "
                    color="secondary"
                    style={{
                      position: 'relative',
                      top: 0,
                      left: 10,
                      background: red[500],
                      color: grey[50],
                    }}
                  />
                </Grid>

                {/* {
                respArray.length === 0 ? true : <Grid item xs={12}><Typography variant="h6" color="inherit" jdisplay="inline">Selecciona la respuesta correcta:</Typography></Grid> 
              } */}



                {/* <Grid item xs={12} hidden={check} >
                  <Chip
                    icon={<Clear />}
                    label="Debes seleccionar la respuesta correcta. "
                    color="secondary"
                    style={{
                      position: 'relative',
                      top: 5,
                      left: 0,
                      background: red[500],
                      color: grey[50],
                    }}
                  />
                </Grid> */}


                <Grid item xs={12}>

                  <Grid container spacing={0} direction="column" justify="space-around">

                    {respArray.map((post, index) => {
                      return (
                        <Grid item key={post.num}>
                          <Grid container direction="row">

                            <Resp borrar={deleteEvent.bind(this, index)} />

                            <RadioGroup value={correct} onChange={rightAnswer} >
                              <FormControlLabel value={post.respuesta} control={<Radio style={{ color: '#ee7203' }} />} label={post.respuesta} />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      );
                    })}

                    <Grid item hidden={check} >
                      <Chip
                        icon={<Clear />}
                        label="Debes seleccionar la respuesta correcta. "
                        color="secondary"
                        style={{
                          position: 'relative',
                          top: 5,
                          left: 0,
                          background: red[500],
                          color: grey[50],
                        }}
                      />
                    </Grid>

                  </Grid>

                </Grid>


              </Grid>
            </CardContent>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Card>
    </div>
    
    <div>
    <Dialog
        open={opcEtiq}
        onClose={handleClose}
    // style={{height:850, width: 900}}
    >
        <DialogTitle>Selecciona las etiqueta para tu pregunta</DialogTitle>

        <DialogContent>

        <ClickAwayListener onClickAway={handleClose}>
                          <FormControl>
                            <FormGroup>
                            {
                                etiqSelec.map((val,index)=>{
                                  return(
                                    <FormControlLabel
                                    key={index}
                                    control={<Checkbox checked={val.state} onChange={assignTag.bind(this, index)} value={val.nom} />}
                                    label={<Typography variant="subtitle1" > {val.nom} </Typography>}
                                    />
                                  )
                                })
                              }

                            </FormGroup>
                          </FormControl>
       </ClickAwayListener>

        </DialogContent>


        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Cerrar
            </Button>
        </DialogActions>
    </Dialog>
</div>
    </div>
  );

}
export default Preg;
