import React, { useState , useEffect, useCallback, useMemo} from 'react';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Logo from './usoGeneral/logo.png';
import { amber, green } from '@material-ui/core/colors';
import 'typeface-roboto';

import Countdown from 'react-countdown-now';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/AccessTime';
import { ListItemText, Radio, RadioGroup, FormControlLabel, ListItem, TextField } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import Highlight from "react-highlight";
import Slide from '@material-ui/core/Slide';
import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/Navigation';
import Swal from "sweetalert2";
import {GLOBAL, THISGLOBAL} from "./usoGeneral/Global";

const styles = theme => ({
    card: {
        minWidth: 275,
    },
    contenedor: {
        position: 'static',
        top: 100,
    },
    time: {
        backgroundColor: amber[700],
        "&:hover": {
            background: amber[500],
        }
    },
    button: {
        margin: theme.spacing.unit,
        backgroundColor: '#ee7203',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#ff9800',
        },
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
          backgroundColor: green[600],
        },
      },
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}


function Examen(props){

    const { classes } = props;

    const [ tiempo , setTiempo ] = useState(1);
    const [ examen , setExamen ] = useState("");
    const [ preguntas , setPreguntas ] = useState([]);
    const [ control , setControl ] = useState(0);
    const [ respArray , setRespArray ] = useState([]);
    const [ showCode , setShowCode ] = useState(false);
    const [ code , setCode ] = useState("");
    const [ showImg , setShowImg ] = useState(false);
    const [ imagen , setImagen ] = useState("");

    var beautify = require('js-beautify').js;
    
    // const activaCode = (index) =>{
    //     const copy = preguntas;
    //     setCode(copy[index].codigo);
    //     setShowCode(true);
    //     // DialCode(true, code);
    // };

    const activaCode = useCallback(
        (index, cod) => {
            console.log(index)
            setCode(cod);
            setShowCode(true);
        },
        [], // Tells React to memoize regardless of arguments.
      );

    const activaImg = (index) =>{
        const copy = preguntas;
        setImagen(copy[index].imagen);
        setShowImg(true);
    };

    const closeImg = () => {
        setShowImg(false);
    };

    const closeCode = ()=>{
        setShowCode(false);
    };

    const rightAnswer = (index, res) =>{
        // setCorrect(res.target.value);
        const copia = respArray;
        copia[index].respuesta = res.target.value;
        setRespArray(copia);
        console.log(copia);
    };

    const sendTest = () =>{
        const params = new URL(window.location.href).searchParams;
                    let pack ={
                        idasp: params.get('asp'),
                        idcue: params.get('id'),
                        respuestas: respArray,
                    };
                    // console.log(pack);
            
                    let url = GLOBAL+'califica'
                    axios.put(url , pack,)
                    .then(response => {
                        console.log(response);
                    })
                    .catch(error => {
                        console.log(error);
                    });
    };

    const Termina = () =>{
        Swal.fire({
            title: '¿Estas seguro que desea teminar la evaluación?',
            text: "No podrás vover a itentarlo",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Terminar'
        }).then((result) => {
            if (result.value) {
                sendTest();
                Swal.fire({
                    type: 'info',
                    title: 'Fin de la evaluación',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    text: 'La evaluación ha finalizado. Para mayor información comunícate a Recursos Humanos.',
                    footer: '<a href></a>'
                })
                    
            }
        })
    };

    const Manda = () =>{
        //para obtener los parámetros de la url
        const params = new URL(window.location.href).searchParams;
        let pack ={
            idasp: params.get('asp'),
            idcue: params.get('id'),
            respuestas: respArray,
        };
        console.log(pack);

        let url = GLOBAL+'califica'
        axios.put(url , pack,)
        .then(response => {
            // console.log(response);
            Swal.fire({
                type: 'info',
                title: 'Fin de la evaluación',
                showConfirmButton: false,
                allowOutsideClick: false,
                text: 'La evaluación ha finalizado. Para mayor información comunícate a Recursos Humanos.',
                footer: '<a href></a>'
            })
        })
        .catch(error => {
            console.log(error);
        });

        return(null)
    };

    //useMemo guarda el valor del tiempo para que no sea alterada por la ejecución de otros componentes
    const memoTiempo = useMemo(() => (Date.now() + 60000*tiempo), [tiempo]);

    useEffect(()=>{
        if(control === 0){
            var url = GLOBAL+"evalua"+window.location.search+"";
            axios.get(url, {headers: {'Content-Type': 'application/json'}})
                .then(response => {
                    if (response.data === null) {
                    } else {
                        // console.log(response.data); 
                        if(response.data.time === 0){
                            window.location.replace(THISGLOBAL+"evaluacion/salir");
                        }else{
                            let copy = Object.assign([], respArray);
                            setControl(1);
                            setTiempo(response.data.time);
                            setExamen(response.data.data.nombrexamen);
                            setPreguntas(response.data.data.preguntas);
                            response.data.data.preguntas.forEach(function(preg){
                                copy.push({respuesta: "", etiqueta: preg.etiqueta});
                            });
                            setRespArray(copy);
                        }
                        
                    }
                    
                })
                .catch(error => console.log(error));
        }
    });

    useEffect(() => {
        window.addEventListener("beforeunload", function(event){
            event.returnValue("Seguro que desea salir?");
        });

        window.addEventListener("unload", function(event) {
            return(<Manda />);
        });
    });

    return(
        <div>
        <div>
            <AppBar position="fixed" color="default" style={{ backgroundColor: '#ee7203' }} >
                <Toolbar style={{ color: "#fafafa" }}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                        <Grid item>
                            <img src={Logo} alt="Logo" width="40" height="40" />
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" color="inherit">
                                Test de Conocimiento Técnico
                            </Typography>
                        </Grid>
                    </Grid>

                    {/* <Button className={classes.time} >
                        {OuterDialog()}
                    </Button> */}

                    <Chip className={classes.time}
                        icon={<FaceIcon />}
                        label={<Countdown
                                    date={memoTiempo}>
                                        <Manda/>
                                </Countdown>}
                        color="secondary"
                    />
                    
                    
                </Toolbar>
            </AppBar>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <Grid container spacing={3} justify="center" >

                <Grid item xs={8}>
                    <Card>

                        <CardContent>
                            <Grid container spacing={2} alignItems="center" justify="center">
                                <Grid item xs={12}>
                                    <Typography variant="h5" color="inherit" align="center">
                                        Cuestionario {examen}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {
                    preguntas.map((val,index)=>{
                        return(
                            <Grid item xs={8}>
                                <Card>
                                    <AppBar position="static">
                                        <Toolbar variant="dense" className="pregunta2 panelesecond">
                                            <ListItemText  primary={<Typography variant="h6" style={{color: 'black'}} >{val.pregunta}</Typography>}/>
                                            
                                            
                                            <div hidden={val.codigo === "0" ? true : false}>
                                                <Button variant="contained" onClick={activaCode.bind(this, index, val.codigo)} className={classes.button}>
                                                    Ver codigo
                                                </Button>        
    
                                            </div>

                                            <div hidden={val.imagen === "0" ? true : false}>
                                                <Button variant="contained" onClick={activaImg.bind(this, index)} className={classes.button}>
                                                    Ver imágen
                                                </Button>
                                            </div>

                                        </Toolbar>
                                    </AppBar>
                                    {/* <Grid container
                                            direction="row"
                                            justify= "center"
                                            alignItems="center">

                                            
                                            <ListItemText style={{ backgroundColor: grey[300] }} primary={<Typography variant="h6" color="inherit">{val.pregunta}</Typography>} secondary={val.etiqueta.lenght !== 0 ? <Typography variant="subtitle2" style={{color: '#ee7203'}}>{val.etiqueta.join("   |   ")}</Typography> : "" } />
                                            

                                        </Grid> */}

                                    <CardContent>

                                    <Grid item >
                                        <Grid container direction="row">
                                        <RadioGroup value={respArray[index]} onChange={rightAnswer.bind(this, index)}>
                                    {val.respuestas.map((post) => {
                                        return (                   
                                            <FormControlLabel value={post.respuesta} control={<Radio style={{ color: '#ee7203' }} />} label={post.respuesta} />
                                        );
                                        })}
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                    
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })
                }
            </Grid>

            <Fab variant="extended" color="primary" aria-label="Add" className={classes.fab} onClick={Termina}>
                <NavigationIcon className={classes.extendedIcon} />
                Enviar
            </Fab>
        </div>
        <div>
        <Dialog button
            open={showCode}
            onClose={closeCode}
            maxWidth="md"
            //fullWidth="md"
            fullWidth={true}
            TransitionComponent={Transition}
        >
            
            <ListItem button>
                <DialogContent >
                    <Highlight language="java">{beautify(code, { indent_size: 4, space_in_empty_paren: false })}</Highlight>
                </DialogContent>
            </ListItem>
            

            <DialogActions>
                <Button onClick={closeCode} color="primary">
                    ok
            </Button>
            </DialogActions>

        </Dialog>
    </div>
    <div>
        <Dialog button
            open={showImg}
            onClose={closeImg}
            maxWidth="md"
            //fullWidth="md"
            fullWidth={true}
            TransitionComponent={Transition}
        >
            
            <ListItem button>
                <DialogContent button >
                    <center><img src={imagen} alt="Preview" width="400px"/></center>
                </DialogContent>
            </ListItem>
            

            <DialogActions>
                <Button onClick={closeImg} color="primary">
                    ok
            </Button>
            </DialogActions>

        </Dialog>
    </div>
    </div>
    );

}
Examen.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Examen);