//Misma estructura de la creación de componentes pero recibe un JSON con la información, se dejará modificar
// la información, se vuelven a hacer validaciones antes de guardar cambios.
import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Preg from './secundarios/PreguntasActualiza';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Logo from './usoGeneral/logo.png';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Refresh from '@material-ui/icons/Refresh';
import Done from '@material-ui/icons/Done';
import { green, blue, amber, red, grey } from '@material-ui/core/colors';
import Clear from '@material-ui/icons/Clear';
import PriorityHigh from '@material-ui/icons/PriorityHigh'
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import 'typeface-roboto';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { DialogTitle, IconButton , OutlinedInput} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import LoadingOverlay from 'react-loading-overlay';
import Burger from './Menu';
import {GLOBAL} from "./usoGeneral/Global";

const styles = theme => ({
    card: {
        minWidth: 275,
    },
    contenedor: {
        position: 'static',
        top: 100,
    },
    fabButton1: {
        position: 'absolute',
        top: 7,
        right: 275,
        margin: '0 auto',
        background: amber[700],
        "&:hover": {
            background: amber[500],
        }
    },
    fabButton11: {
        position: 'relative',
        margin: '0 auto',
        background: "#ee7203",
        "&:hover": {
            background: amber[500],
        }
    },
    fabButton2: {
        position: 'absolute',
        top: 7,
        right: 200,
        margin: '0 auto',
        background: amber[700],
        "&:hover": {
            background: blue[500],
        }
    },
    fabButton3: {
        position: 'absolute',
        top: 7,
        right: 125,
        margin: '0 auto',
        background: amber[700],
        "&:hover": {
            background: green[500],
        }
    },
    botonDeBorrado: {
        position: 'absolute',
        bottom: -20,
        left: 0,
        margin: '0 auto',
        background: red[500],
        "&:hover": {
            background: red[300],
        }
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    lista: {
        width: '100%',
        maxWidth: 400,
        maxHeight: 200,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
    },
});

//Actualizada a servicios de NodeJS | Mongoose

function Update(props) {
    const { classes } = props;

    const [nombreExamen, setnombreExamen] = useState("");
    const [numPregunta, setNumPregunta] = useState(0);
    const [pregArray, setPregArray] = useState([]);
    const [result, setResult] = useState([]);
    const [aviso, setAviso] = useState(false);
    const [aviso2, setAviso2] = useState(false);
    const [aviso3, setAviso3] = useState(false);
    const [cargar, setCargar] = useState(false);
    const [alertaBorra, setAlerta] = useState(false);
    const [indice, setIndice] = useState(0);
    const [control, setControl] = useState(0);
    const [choice, setChoice] = useState(0);
    const [errUpdate, setErrUpdate] = useState(false);
    const [dialogSec, setDialogSec] = useState(false);
    const [etiquetas, setEtiq] = useState([]);
    const [neuEtiq, setNeuEtiq] = useState("");
    const [examenes , setExamenes] = useState([]);
    const [isActive , setActive] = useState(false);


    let archivo = {
        nombrexamen:"", 
        idexamen: 0,
        tipoCuest: 0,
        etiquetas: [], 
        preguntas:[]
    };

    const update = () => {
        reseteo();
        //Se llena el arreglo de preguntas con la info del JSON
        var i, j, aux;
        var zahlen = numPregunta;

        const extractor = Object.assign([], pregArray);

        for (i = 0; i < archivo.preguntas.length; i++) {
            for (j = 0; j < archivo.preguntas[i].respuestas.length; j++) {
                if (archivo.preguntas[i].respuestas[j].correcta === 1) {
                    aux = archivo.preguntas[i].respuestas[j].respuesta;//obtiene la respuesta correcta
                }
            }
            zahlen += 1;//cuenta número de preguntas
            extractor.push({
                num: zahlen,
                key: zahlen,
                bandera: 0,
                estado: true,
                estado2: true,
                estado3: true,
                estado4: true,
                estado5: true,
                checaTag: true,
                pregunta: archivo.preguntas[i].pregunta,
                codigo: archivo.preguntas[i].codigo,
                respuestas: archivo.preguntas[i].respuestas,
                imagen: archivo.preguntas[i].imagen,
                right: aux,
                level: archivo.preguntas[i].nivel,
                etiqueta: archivo.preguntas[i].etiqueta.length === 0 ? [] : archivo.preguntas[i].etiqueta,
            });

        }

        setPregArray(extractor);
        setNumPregunta(zahlen);
        setnombreExamen(archivo.nombrexamen);
        setEtiq(archivo.etiquetas[0] === "" ? [] : archivo.etiquetas);
        setActive(false);
    };

    const addPreg = () => {
        if (choice !== 0) {
            var aumento = numPregunta;
            aumento = aumento + 1;

            const copiaPreg = Object.assign([], pregArray);
            copiaPreg.push({
                num: aumento,
                key: aumento,
                bandera: 0,
                estado: true,
                estado2: true,
                estado3: true,
                estado4: true,
                estado5: true,
                checaTag: true,
                pregunta: "",
                codigo: "0",
                respuestas: [],
                imagen: "0",
                etiqueta: [],
                level: 1
            });

            setPregArray(copiaPreg);
            setNumPregunta(aumento);
        } else {
            setErrUpdate(true);
        }
    };

    const addEtiq = (e) => {
        setNeuEtiq(e.target.value);
    };

    const addTag = () => {
        if (neuEtiq !== "") {
            if((etiquetas.indexOf(neuEtiq)<0)){
                const copy = Object.assign([], etiquetas);
                copy.push(neuEtiq);
                setEtiq(copy);
                setNeuEtiq("");
            }
            setNeuEtiq("");
        }
    };

    const cambio = (e) => {
        setnombreExamen(e.target.value);
    };

    const choiceHandler = (val) => {
        reseteo();
        setChoice(val.target.value);
        setControl(1);
        setActive(true);
        // console.log(val.target.value);
    };

    const borrarPreguntaAviso = (index) => {
        setAlerta(true);
        setIndice(index);
    };

    const borrarPregunta = () => {

        if (pregArray.length === 1) {
            setPregArray([]);
            setNumPregunta(0);
            setAlerta(false);
        } else {
            var copia = pregArray;
            copia.splice(indice, 1);
            setPregArray(copia);
            setAlerta(false);

            var i;
            for (i = 0; i < copia.length; i++) {
                copia[i].num = i + 1;
            }

            setPregArray(copia);
            setNumPregunta(i);
        }
    };

    const deleteTag = (index, e) =>{
        const copia3 = Object.assign([], etiquetas);
        copia3.splice(index,1);
        setEtiq(copia3);
    };

    const handleClose1 = () => {
        setAviso(false);
        setChoice(0);
        setControl(0);
        reseteo();
    };

    const handleClose = () => {
        setAviso2(false);
    };

    const handleRefresh = () => {
        setCargar(false);
    };

    const handle5 = () => {
        setAlerta(false);
    };

    const handle6 = () => {
        setErrUpdate(false);
    }

    const juntaData = data => {

        //Validaciones por el paquete de datos.

        const respCheck = pregArray;
        let copiaPreg = pregArray;
        var i, j;
        var aux;
        var suma = 0;
        var sumatotal = 0;
        var pvacias = 0;
        var sumacod = 0;
        var lessThanTwo = 0;
        var error = 0;
        var even = 0;
        var nameless = 0;

        for (i = 0; i < respCheck.length; i++) {
            suma = 0;

            if (respCheck[i].pregunta === "") { //busca preguntas vacias y acciona aviso
                pvacias += 1; //suma al contador que hay un error de este tipo
                copiaPreg[i].estado2 = false; //cambia el valor para mostrar el Chip de error
                setPregArray(copiaPreg);
            } else {
                copiaPreg[i].estado2 = true; //modifica el estado para que se vea igual
                setPregArray(copiaPreg);
            }


            if (respCheck[i].codigo === "") { //busca código vacío y manda aviso de corrección
                copiaPreg[i].estado3 = false;  // activa el chip de error
                sumacod += 1; // suma uno a lavariable de este error
                setPregArray(copiaPreg);
            } else {
                copiaPreg[i].estado3 = true;//deja el Chip oculto
                setPregArray(copiaPreg);
            }

            if (respCheck[i].imagen === "") {  //busca por imágenes nulas
                copiaPreg[i].estado5 = false;  // activa el chip de error
                sumacod += 1; // suma uno a lavariable de este error
                setPregArray(copiaPreg);
            } else {
                copiaPreg[i].estado5 = true;//deja el Chip oculto
                setPregArray(copiaPreg);
            }

            if(respCheck[i].etiqueta === ""){//busca una pregunta sin tag
                copiaPreg[i].checaTag = false;  //activa el chip de error
                sumacod += 1; //suma un error
                setPregArray(copiaPreg); //guarda cambios
            }else{
                copiaPreg[i].checaTag = true;// deja el chip oculto
                setPregArray(copiaPreg);
            }

            aux = respCheck[i].respuestas //auxiliar con todas las respuestas de cada pregunta

            if (aux.length < 2) { //Verifica que las preguntas tengan al menos 2 respuestas
                copiaPreg[i].estado4 = false; //Ativa el chip de error
                lessThanTwo += 1;
                setPregArray(copiaPreg);
            } else {

                copiaPreg[i].estado4 = true; //deja el chip de error 'Al menos 2 resps por preg' apagado
                setPregArray(copiaPreg);

                if(archivo.tipoCuest === 1){
                    for (j = 0; j < aux.length; j++) {//verifica que siempre exista una respuesta correcta
                        suma += aux[j].correcta;
                    }
    
                    if (suma !== 1) {
                        copiaPreg[i].estado = false;//Activa el Chip de error "Escoge la respuesta correcta"
                        // setAviso2(true);
                        // setPregArray(copiaPreg);
                        // setBandera(0);
                        // setResult([]);
                    } else {
                        copiaPreg[i].estado = true; //Deja el Chip de error "Escoge la respuesta correcta" apagado
                        // setPregArray(copiaPreg);
                        // setBandera(0);
                        // setResult([]);
                    }
                }

            }

            error = error + pvacias + sumacod + lessThanTwo; //Suma de todos los errores

            sumatotal += suma; //suma de todas las respuestas marcadas en el cuestionario

        }

        if (sumatotal !== respCheck.length && archivo.tipoCuest === 1) {//Verifica que halla tantas respuestas correctas como hay preguntas
            even = 1; //Si no hay la misma cantidad se asigna uno a 'even'
        }

        if (nombreExamen === "") { //Verifica que el cuestionario tenga nombre
            nameless = 1;
            setAviso3(true); //Activar Chip de error
        } else { setAviso3(false); }

        error = error + even + nameless;

        if (error !== 0) {
            setAviso2(true);
            setPregArray(copiaPreg);
        } else {
            if (error === 0) { //Verifica que no hubo errores y que el tamaño del Json sea correcto

                let t, auxiliar;

                for (t = 0; t < pregArray.length; t++) {
                    auxiliar = result;
                    auxiliar.push({
                        pregunta: pregArray[t].pregunta,
                        codigo: pregArray[t].codigo,
                        respuestas: pregArray[t].respuestas,
                        imagen: pregArray[t].imagen,
                        etiqueta: pregArray[t].etiqueta,
                        nivel: pregArray[t].level
                    })
                    setResult(auxiliar);
                }

                let pack = {
                    nombrexamen: nombreExamen,
                    _id: choice,
                    etiquetas: etiquetas,
                    preguntas: result,
                };

                // console.log('%c Prueba JSON', 'color: green; font-weight: bold;');
                // console.log(JSON.stringify(pack));


                
                    // axios
                    //     .post('http://apps.badak.io/examen/nuevaspreguntas', pack, {
                    axios 
                        .put(GLOBAL+'actualizar', pack, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    })
                    .then(response => {
                        // console.log(response);
                        setAviso(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });

                    // console.log(JSON.stringify(pack));

                

            }
        }

    };

    const mandaTodo = () => {
        if (pregArray.length === 0) {
            alert("Añade al menos una pregunta al cuestionario");
        } else {
            juntaData();
        }
    };

    const reinicio = () => {
        setCargar(true);
    };

    const reseteo = () => {
        setnombreExamen("");
        setNumPregunta(0);
        setPregArray([]);
        setResult([]);
        setAviso(false);
        setAviso2(false);
        setAviso3(false);
        setCargar(false);
        setEtiq([]);
        setNeuEtiq("");
    };

    const savePreg = (index, que) => { //Guardamos la información generada del componente hijo en el mismo arreglo del padre.
        const copia = pregArray;
        copia[index].pregunta = que;
        setPregArray(copia);
    };

    const saveCod = (index, codi) => {
        const copia = pregArray;
        copia[index].codigo = codi;
        setPregArray(copia);
    };

    const saveResp = (index, respi) => {
        const copia = pregArray;
        copia[index].respuestas = respi;
        setPregArray(copia);
    };

    const saveImage = (index, img) => {
        const copia = pregArray;
        if (img === "0") {
            copia[index].imagen = "0";
        } else {
            if (img === "") {
                copia[index].imagen = "";
            } else {
                copia[index].imagen = img;
            }
        }
        setPregArray(copia);
    };

    const nuevaSeccion = () => {
        setDialogSec(!dialogSec);
    };

    const saveTag = (index, etq) =>{
        const copia = pregArray;
        copia[index].etiqueta = etq;
        setPregArray(copia);
        
    };

    const saveLevel = (index, lv) => {
        const copia = pregArray;
        copia[index].level = lv;
        setPregArray(copia);
    };

    useEffect(() => {
        if (choice === 0){
             //pedimos los cuestionarios a bd
            //var url = "http://apps.badak.io/examen/examenes";
            var url = GLOBAL+"/examenes";
            axios.get(url, {headers: {'Content-Type': 'application/json'}})
                .then(response => {
                    if (response.data === null) {
                    } else {
                        // console.log(response.data);
                        setExamenes(response.data);
                    }
                    
                })
                .catch(error => console.log(error));
        }
                
    });

    useEffect(() =>{
         // Update the document title using the browser API

         if ( choice !== 0 &&control !== 0) {//Para que sólo cargue el cuestionario una vez que se haya seleccionado un cuestionario
            
            // eslint-disable-next-line
            // var url2 = "http://localhost:8080/examen/actualizacion"+ "?id=" + choice ;
            // eslint-disable-next-line
            //var url2 = "http://apps.badak.io/examen/actualizacion"+ "?id=" + choice ;
            // eslint-disable-next-line
            var url2 = GLOBAL+"cuestionario?id=" + choice +"";

            axios.get(url2)
            .then(response => {
                if (response === null) {
                } else {
                    var objt = response.data;
                    // console.log(response.data);
                    var objt2 = {
                        nombrexamen: objt.nombrexamen,
                        tipoCuest: objt.tipo,
                        idexamen: objt.idexamen,
                        etiquetas: objt.etiquetas, 
                        preguntas: objt.preguntas
                    };
                    
                    console.log(objt2.preguntas);
                    archivo = objt2;
                    update();
                    setControl(0);
                }
            })
            .catch(error => console.log(error));

            
         }

    },[choice]);

 
    return (
        <div>
            <LoadingOverlay
                active={isActive}
                spinner={true}
                text='Cargando cuestionario...'
                >
            
            <Grid container justify='center' alignItems='center' spacing={3}>

                <Grid item sm={12} xs={12}>
                    <AppBar position="fixed" color="default" style={{ backgroundColor: '#ee7203' }} >
                        <Toolbar style={{ color: "#fafafa" }}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item>
                                    <img src={Logo} alt="Logo" width="40" height="40"/>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" color="inherit">
                                        Actualizar Cuestionario
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>

                                <Grid item>
                                    <Tooltip TransitionComponent={Zoom} title="Añadir Pregunta">
                                        <Fab size="medium" color="secondary" className={classes.fabButton1} onClick={addPreg}>
                                            <AddIcon />
                                        </Fab>
                                    </Tooltip>
                                </Grid>

                                <Grid item>
                                    <Tooltip TransitionComponent={Zoom} title="Reiniciar el cuestionario">
                                        <Fab size="medium" color="secondary" className={classes.fabButton2} onClick={reinicio}>
                                            <Refresh />
                                        </Fab>
                                    </Tooltip>
                                </Grid>

                                <Grid item>
                                    <Tooltip TransitionComponent={Zoom} title="Guardar el cuestionario">
                                        <Fab size="medium" color="secondary" className={classes.fabButton3} onClick={mandaTodo}>
                                            <Done />
                                        </Fab>
                                    </Tooltip>
                                </Grid>

                                <Grid item>
                                    <Burger/>
                                </Grid>

                            </Grid>  

                        </Toolbar>          
                    </AppBar>
                </Grid>
                <br/><br/><br/><br />

                <Grid item sm={8} xs={11}>
                    <Card>
                        <CardContent >

                            <Grid container spacing={3} alignItems="center" justify="space-evenly">

                                <Grid item sm={12} xs={12}>
                                    <Typography variant="h5" color="inherit" align='center'>
                                        Modificador de Cuestionarios
                                    </Typography>
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    <Typography variant="h5" color="inherit" align='right'>
                                        Cuestionario por actualizar:
                                    </Typography>
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    <FormControl variant="outlined" fullWidth={true}>
                                        <InputLabel>Cuestionario</InputLabel>
                                        <Select
                                            value={choice}
                                            onChange={choiceHandler}
                                            input={<OutlinedInput labelWidth={100} />}
                                            inputProps={{
                                                name: 'Cuestionarios',
                                            }}
                                        >
                                        {
                                            examenes.map((i) => {
                                                return(
                                                    <MenuItem value={i._id} key={i._id}>{i.nombrexamen}</MenuItem>
                                                )
                                            })
                                        }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {
                                choice !== 0 ? <Grid item sm={10} xs={12}><TextField
                                    label="Nombre del cuestionario"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth={true}
                                    value={nombreExamen}
                                    onChange={cambio}
                                /></Grid> : true
                                }

                                {
                                    choice !== 0 ? <Grid item sm={2} xs={8}><Button variant="outlined" style={{ bottom: 10 }} onClick={nuevaSeccion}>
                                    Secciones : {etiquetas.length}
                                </Button></Grid> : true
                                }

                                {
                                    aviso3 ? <Chip icon={<Clear />} label="Debes darle un nombre a tu cuestionario. " color="secondary" style={{ position: 'relative', top: 5, left: 0, background: red[500], color: grey[50], }} /> : false
                                }

                            </Grid>  
        
                        </CardContent>
                    </Card>
                </Grid>

                {
                    pregArray.map((valor, index) => {
                        return (
                            <Grid key={index} item sm={8} xs={11}>
                                {
                                    valor.estado && valor.estado2 && valor.estado3 && valor.estado4 && valor.estado5 && valor.checaTag ? null : <Fab hidden={true} size="small" color="secondary" aria-label="Delete" style={{ position: 'relative', bottom: -60, left: -20, flex: 1, zIndex: 999, background: red[500] }}><PriorityHigh /></Fab>
                                }
                                <Preg
                                    num={valor.num}
                                    check={valor.estado}
                                    check1={valor.estado4}
                                    check2={valor.estado2}
                                    check3={valor.estado3}
                                    check4={valor.estado5}
                                    check5={valor.checaTag}
                                    reg_preg={savePreg.bind(this, index)}
                                    reg_cod={saveCod.bind(this, index)}
                                    reg_resp={saveResp.bind(this, index)}
                                    question={valor.pregunta}
                                    code={valor.codigo}
                                    answers={valor.respuestas}
                                    borra={borrarPreguntaAviso.bind(this, index)}
                                    imag={valor.imagen}
                                    recibeimg={saveImage.bind(this, index)}
                                    right={valor.right} //necesaria en update 'Respuesta correcta'
                                    etiquetas={etiquetas}
                                    tag={valor.etiqueta}
                                    reg_tag={saveTag.bind(this, index)}
                                    level={valor.level}
                                    reg_level={saveLevel.bind(this, index)}
                                />
                            </Grid>
                        )
                    })
                }

            </Grid>

            <div>
                <Dialog
                    open={aviso}
                    onClose={handleClose1}
                >
                    <DialogContent>
                        <Typography>
                            El cuestionario se ha guardado correctamente.
                            </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose1} color="primary">
                            OK
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog
                    open={aviso2}
                    onClose={handleClose}
                >
                    <DialogContent>
                        <Typography>
                            Por favor corrige los errores en el cuestionario antes de guardarlo.
                            </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            OK
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog
                    open={cargar}
                    onClose={handleRefresh}
                >
                    <DialogContent>
                        <Typography>
                           ¿Estás seguro que deseas reiniciar el cuestionario?
                            </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={reseteo} color="primary">
                            Si
                    </Button>
                        <Button onClick={handleRefresh} color="primary">
                            No
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog
                    open={alertaBorra}
                    onClose={handle5}
                >
                    <DialogContent>
                        <Typography>
                            ¿Estás seguro que deseas borrar esta pregunta?
                            </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={borrarPregunta} color="primary">
                            Si
                    </Button>
                        <Button onClick={handle5} color="primary">
                            No
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog
                    open={errUpdate}
                    onClose={handle6}
                >
                    <DialogContent>
                        <Typography>
                            Primero selecciona un cuestionario para añadirle una pregunta.
                            </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handle6} color="primary">
                            ok
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog
                    open={dialogSec}
                    onClose={nuevaSeccion}
                // style={{height:850, width: 900}}
                >
                    <DialogTitle>Secciones en el cuestionario</DialogTitle>
                    <DialogContent>

                        <List dense={false} className={classes.lista}>
                        <Grid container  direction="column"  justify="flex-start" alignItems="flex-start" spacing={0}>
                            {
                                etiquetas.map((v,index) => {
                                    return (
                                            <Grid item xs={12} key={index}>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <IconButton onClick={deleteTag.bind(this, index)} > 
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </ListItemIcon>
                                                <ListItemText primary={v} />
                                            </ListItem>
                                            </Grid>
                                    )
                                })
                            }
                             </Grid>
                        </List>

                        <Grid container
                            direction="row"
                            justify="space-around"
                            alignItems="center">
                            <Grid item xs={10}>
                                <TextField
                                    label="Nueva etiqueta"
                                    margin="normal"
                                    fullWidth={true}
                                    value={neuEtiq}
                                    onChange={addEtiq} />
                            </Grid>
                            <br />
                            <br />
                            <Grid item xs={2}>
                                <Fab size="small" color="secondary" className={classes.fabButton11} onClick={addTag}>
                                    <AddIcon />
                                </Fab>
                            </Grid>
                        </Grid>

                    </DialogContent>

                    <DialogActions>

                        <Button onClick={nuevaSeccion} color="primary">
                            Cerrar
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>

            </LoadingOverlay>
        </div>
    );
}
Update.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Update);
