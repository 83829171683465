import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';

import BarChart from '@material-ui/icons/BarChart'
import Search from '@material-ui/icons/Search';
import ListAlt from '@material-ui/icons/ListAlt';
import Burguer from '@material-ui/icons/Menu';
import People from '@material-ui/icons/People';
import Refresh from '@material-ui/icons/Refresh';
import { amber } from '@material-ui/core/colors';
import { Link } from "react-router-dom";


const styles = theme => ({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },

    fabButton4: {
        position: 'absolute',
        top: 7,
        right: 50,
        margin: '0 auto',
        background: amber[700],
        "&:hover": {
            background: '#9e9e9e',
        }
    },
});

// const classes = styles;

function Burger(props) {

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const { classes } = props;

    const toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [side]: open });
    };

    const sideList = side => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            <List>
                <ListItem component={Link} to="/evaluacion/nuevo" button>
                    <ListItemIcon>
                        <ListAlt />
                    </ListItemIcon>
                    <ListItemText primary="Nuevo Cuestionario" />

                </ListItem>
                <ListItem component={Link} to="/evaluacion/actualiza" button>
                    <ListItemIcon>
                        <Refresh />
                    </ListItemIcon>
                    <ListItemText primary="Actualiza Cuestionario" />
                </ListItem>
                <ListItem component={Link} to="/evaluacion/resultados" button>
                    <ListItemIcon>
                        <Search />
                    </ListItemIcon>
                    <ListItemText primary="Resultados" />
                </ListItem>
            </List>
            <Divider />
            <List>

                <ListItem component={Link} to="/evaluacion/registro" button>
                    <ListItemIcon>
                        <People />
                    </ListItemIcon>
                    <ListItemText primary="Registro" />
                </ListItem>

                <ListItem component={Link} to="/evaluacion/consultas" button>
                    <ListItemIcon>
                        <BarChart />
                    </ListItemIcon>
                    <ListItemText primary="Consultas" />
                </ListItem>

                <ListItem component={Link} to="/evaluacion/invitacion" button>
                    <ListItemIcon>
                        <MailIcon />
                    </ListItemIcon>
                    <ListItemText primary="Enviar invitación" />
                </ListItem>

            </List>
        </div>
    );



    return (
        <div>
            <Tooltip TransitionComponent={Zoom} onClick={toggleDrawer('right', true)} title="Menú">
                <Fab size="medium" color="secondary" className={classes.fabButton4} >
                    <Burguer />
                </Fab>
            </Tooltip>
            <div>
                <SwipeableDrawer
                    anchor="right"
                    open={state.right}
                    onClose={toggleDrawer('right', false)}
                    onOpen={toggleDrawer('right', true)}
                >
                    {sideList('right')}
                </SwipeableDrawer>

            </div>
        </div>
    );
}


export default withStyles(styles)(Burger);
