import React from 'react';
import Swal from "sweetalert2";


export function Final() {
  Swal.fire({
    type: 'info',
    title: 'Fin de la evaluación',
    showConfirmButton: false,
    allowOutsideClick: false,
    text: 'La evaluación ha finalizado. Para mayor información comunícate a Recursos Humanos.',
    footer: '<a href></a>'
  })
  return(
    <div>
    </div>
  )
}

export default Final;
