import React, { useState , useEffect} from 'react';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {TextField, List, ListItem, ListItemText, ListItemIcon, ListSubheader, CircularProgress, FormGroup, FormControl, FormControlLabel, Checkbox} from '@material-ui/core';
import {Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, Select, MenuItem, OutlinedInput, Button} from '@material-ui/core';
import {Card, CardContent, Toolbar, Typography, Grid} from '@material-ui/core';
import Logo from './usoGeneral/logo.png';
import { green, blue, amber, red } from '@material-ui/core/colors';

import 'typeface-roboto';
import SendteIcon from '@material-ui/icons/Send';
import axios from 'axios';
import Burger from './Menu';
import {Person} from '@material-ui/icons';
import Swal from "sweetalert2";
import {GLOBAL} from './usoGeneral/Global';

const styles = theme => ({
    card: {
        minWidth: 275,
    },
    card2:{
        borderRadius: '20px'
    },
    card3: {
        borderRadius: '100px'
    },
    contenedor: {
        position: 'static',
        top: 100,
    },
    fabButton1: {
        position: 'absolute',
        top: 7,
        right: 200,
        margin: '0 auto',
        background: amber[700],
        "&:hover": {
            background: amber[500],
        }
    },
    fabButton11: {
        position: 'relative',
        margin: '0 auto',
        background: "#ee7203",
        "&:hover": {
            background: amber[500],
        }
    },
    fabButton2: {
        position: 'absolute',
        top: 7,
        right: 125,
        margin: '0 auto',
        background: amber[700],
        "&:hover": {
            background: blue[500],
        }
    },
    fabButton3: {
        position: 'absolute',
        top: 7,
        right: 50,
        margin: '0 auto',
        background: amber[700],
        "&:hover": {
            background: green[500],
        }
    },
    botonDeBorrado: {
        position: 'absolute',
        bottom: -20,
        left: 0,
        margin: '0 auto',
        background: red[500],
        "&:hover": {
            background: red[300],
        }
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    lista: {
        width: '100%',
        maxWidth: 400,
        maxHeight: 200,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
    },
    botonSend: {
        position: 'relative',
        margin: '0 auto',
        background: "#3aa832",
        "&:hover": {
            background: "#36b82c",
        }
    },
});

function Invitacion(props){

    const { classes } = props;

    const tiempos = [{time: "20 minutos", min: 30},{time: "45 minutos", min: 45},{time: "1 hora", min: 60},{time: "1 hora y media", min: 90},{time: "2 horas", min: 120}];
    const tipoExamen = [{tipo:1, class: "Examen de evaluación técnico"},{tipo: 2, class: "Evaluación 360"}];
    const periodos = [{num: 0, mes:"Enero"},{num: 1, mes:"Febrero"},{num: 2, mes:"Marzo"},{num: 3, mes:"Abril"},{num: 4, mes:"Mayo"},{num: 5, mes:"Junio"},
    {num: 6, mes:"Julio"},{num: 7, mes:"Agosto"},{num: 8, mes:"Septiembre"},{num: 9, mes:"Octubre"},{num: 10, mes:"Noviembre"},{num: 11, mes:"Diciembre"}]

    const [ evaluadores , setEvaluadores ] = useState([]); //arreglo simple de evaluadores
    const [ entidades , setEntidades ] = useState([]); //arreglo simple con entidades
    const [ examenes, setExamenes ] = useState([]); // arreglo con examenes disponibles de ambos tipos
    const [ nombre , setNombre ] = useState(""); // nombre del candidato a evaluar
    const [ correo , setCorreo ] = useState(""); //correo del candidto a evaluar
    const [ tecnologia , setTecno ] = useState(""); // tecnologia seleccionada
    const [ minutos , setMinutos ] = useState(""); //minutos seleccionados
    const [ evalData , setEvalData ] = useState({}); // informacion del registro del evaluador
    const [ recArray , setRecArray ] = useState([]); // arreglo con recursos asignados al evaluador

    const [ tipoEx , setTipoEx ] = useState(1); // controlador de tipo de examen a realizar
    const [ control , setControl ] = useState(0); // controlador para useEffect
    const [ selEntidad , setSelEntidad ] = useState(""); //controlador del combo de entidades
    const [ selEvaluador , setSelEvaluador ] = useState(""); //controlador del combo de evaluadores
    const [ periodo , setPeriodo ] = useState(""); // controlador del combo de peridos del año
    const [ selCue , setSelCue ] = useState(""); //controlador de combo de cuestionarios de evaluacion aRecurso
    const [ progressFlag , setProgressFlag ] = useState(false); //controlador de circularProgress 
    const [ dialog1 , setDialog1 ] = useState(false);

    // Traer datos de evaluadores y entdades desde API
    const getData = () =>{
        axios //traemos entidades en BD
        .get(GLOBAL+'registro?tipo=4')
        .then(response => {
            setEntidades(response.data);
        })
        .catch(error => {
            console.log(error);
        });

        axios //traemos evaluadores en BD
        .get(GLOBAL+'registro?tipo=5')
        .then(response => {
            setEvaluadores(response.data);
        })
        .catch(error => {
            console.log(error);
        });

        axios //traemos cuestionarios en BD
        .get(GLOBAL+'registro?tipo=7')
        .then(response => {
            setExamenes(response.data);
        })
        .catch(error => {
            console.log(error);
        });

        setControl(1);

    };

    const handlerNombre = (e) =>{
        setNombre(e.target.value);
    };

    const handlerCorreo = (e) =>{
        setCorreo(e.target.value);
    };

    const handlerTecno = (e) =>{
        setTecno(e.target.value);
    };

    const handlerMin = (e) =>{
        setMinutos(e.target.value);
    };

    const handlerTipo = (e) =>{
        setTipoEx(e.target.value);
    };

    const handlerEnviarTecnico = () =>{

        setProgressFlag(true);

        if(nombre === "" || tecnologia === "" || correo === "" || minutos === ""){
            Swal.fire({
                type: 'warning',
                title: 'Completa los campos requeridos',
                showConfirmButton: false,
                timer: 1500
            })
        }else{
            let data={
                tipo: 4,
                tipoInv: 1,
                nombre: nombre,
                correo: correo,
                id_tecnologia: tecnologia,
                tiempo: minutos  
            };

            let url = GLOBAL+"registro";

            axios.post(url, data, {headers: {'Content-Type': 'application/json'}})
                .then(response => {
                    if (response.data === null) {
                    } else {
                        setProgressFlag(false);
                        if(response.status === 200){
                            Swal.fire({
                                type: 'success',
                                title: 'Invitación enviada',
                                showConfirmButton: false,
                                timer: 1500
                            });
                            reset(); 
                        }else{
                            if(response.status === 400){
                                Swal.fire({
                                    type: 'error',
                                    title: 'Error de envio',
                                    text: 'Por el momento no ha sido posible enviar la invitación',
                                    showConfirmButton: false,
                                    timer: 3000
                                });
                            }
                        }
                        
                             
                    }
                    
                })
                .catch(error => console.log(error));            
        }
        
        
    };

    const handlerEnviarEval = () => {

        if(periodo === "" || selCue === "" || selEntidad === "" || selEvaluador === ""){
            Swal.fire({
                type: 'warning',
                title: 'Completa los campos requeridos',
                showConfirmButton: false,
                timer: 1500
            })
        }else{
            let data = {
                tipo: 4,
                tipoInv: 2,
                evalPeriodo: periodo,
                evalCuest: selCue,
                evalEntidad: selEntidad,
                evalEvaluador: selEvaluador,
                correo: evalData.S_evaCorreo
            };

            let url = GLOBAL+"registro";

            axios.post(url, data, {headers: {'Content-Type': 'application/json'}})
                .then(response => {
                    if (response.data === null) {
                    } else {
                        Swal.fire({
                            type: 'success',
                            title: 'Invitación enviada',
                            showConfirmButton: false,
                            timer: 1500
                        })  
                        reset();      
                    }
                    
                })
                .catch(error => console.log(error));            
        }

    };

    //Controla selección del combo de entidades
    const handlerFormEnt = (e) =>{
        setSelEntidad(e.target.value);
        setEvalData({});
        setRecArray([]);
    };

    //Controla selección del combo de evaluadores y trae data desde API
    const handlerFormEva = (e) =>{

        setSelEvaluador(e.target.value);

        axios 
        .get(GLOBAL+'registro?tipo=6&id='+e.target.value)
        .then(response => {
            setEvalData(response.data);
            setRecArray(response.data.A_evaRecursos);
            // console.log(response.data);
        })
        .catch(error => {
            console.log(error);
        });
        
    };

    //controlador del combo de periodos
    const handlerPeriodos = (e) =>{
        setPeriodo(e.target.value);
    };

    //controlador del combo de cuestionarios de evaluacion a recursos
    const handlerSelCue = (e) =>{
        setSelCue(e.target.value);
    };

    const reset =() =>{
        setNombre("");
        setCorreo("");
        setTecno("");
        setMinutos("");
        setControl(0);
        setPeriodo("");
        setSelCue("");
        setSelEntidad("");
        setSelEvaluador("");
        setEvalData({});
        setRecArray([]);
    };

    const WhiteCircularProgress = withStyles({
        root: {
          color: '#ffffff',
        },
      })(CircularProgress);

    useEffect(()=>{
        if(control === 0){    
            getData();
        }
    });

    return(
        <div>
            <Grid container direction='row' justify='center' alignContent='center' spacing={3}>

                <Grid item xs={12} sm={12}>
                    <AppBar position="fixed" color="default" style={{ backgroundColor: '#ee7203' }} >
                        <Toolbar style={{ color: "#fafafa" }}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item>
                                    <img src={Logo} alt="Logo" width="40" height="40" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" color="inherit">
                                        Enviar Invitación
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Burger />
                        </Toolbar>
                    </AppBar>
                </Grid>     
                <br/><br/><br/><br/>

                <Grid item sm={8} xs={11}>

                    <Card>

                        <CardContent >

                            <Grid container spacing={3} alignItems="center" justify="space-evenly">

                                <Grid item sm={12} xs={12} >
                                    <Typography variant="h5" color="inherit" align="center">
                                       Manejador de invitaciones
                                    </Typography>
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    <Typography variant="h5" color="inherit" align="right"> 
                                        Tipo de invitación :
                                    </Typography>
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    <FormControl variant="outlined" fullWidth={true} >
                                        <InputLabel>Opciones</InputLabel>
                                        <Select
                                        value={tipoEx}
                                        onChange={handlerTipo}
                                        input={<OutlinedInput labelWidth={70} />}
                                        >
                                        {
                                            tipoExamen.map((data, index) => {
                                                return(
                                                    <MenuItem key={index} value={data.tipo}>{data.class}</MenuItem>
                                                )
                                            })
                                        }
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Grid>

                                {tipoEx === 1 ? 
                                    <Grid container spacing={3} alignItems="center" justify="space-evenly">
                                        {/* Invitacion cuestionario tecnico */}
                                    <Grid item  sm={12} xs={12} >
                                    <TextField 
                                        label="Nombre del aspirante"
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth={true}
                                        value={nombre}
                                        onChange={handlerNombre}
                                    />
                                </Grid>

                                <Grid item  sm={12} xs={12} >
                                    <TextField 
                                        label="Correo del aspirante"
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth={true}
                                        value={correo}
                                        onChange={handlerCorreo}
                                    />
                                </Grid>
                                
                                
                                <Grid item  sm={8} xs={12}>
                                    <FormControl variant="outlined" fullWidth={true}>
                                        <InputLabel>Cuestionario técnico</InputLabel>
                                        <Select
                                        value={tecnologia}
                                        onChange={handlerTecno}
                                        // input={<OutlinedInput labelWidth={150} />}
                                        >
                                        {
                                            examenes.map((data, index) => {
                                                if(data.tipo === 1){
                                                    return(
                                                        <MenuItem key={index} value={data._id} >{data.nombrexamen}</MenuItem>
                                                    )
                                                }else{
                                                    return(true)
                                                }
                                            })
                                        }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item  sm={4} xs={12}>
                                    <FormControl variant="outlined" fullWidth={true} >
                                        <InputLabel>Tiempo para resolverlo</InputLabel>
                                        <Select
                                        value={minutos}
                                        onChange={handlerMin}
                                        input={<OutlinedInput labelWidth={165} />}
                                        >
                                        {
                                            tiempos.map((data, index) => {
                                                return(
                                                    <MenuItem key={index} value={data.min}>{data.time}</MenuItem>
                                                )
                                            })
                                        }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item  sm={5} xs={8} >
                                    <Button variant="contained" color="primary" className={classes.botonSend} fullWidth={true} onClick={handlerEnviarTecnico}>
                                        {
                                            progressFlag ?  <WhiteCircularProgress size={30} thickness={5}  /> : <>Enviar &nbsp; <SendteIcon className={classes.rightIcon} /> </>
                                        }                                        
                                    </Button>
                                </Grid>

                                </Grid> : <Grid container spacing={3} alignItems="center" justify="space-evenly">
                                    {/* Invitacion evaluacion recurso */}

                                <Grid item sm={6} xs={12}>
                                <FormControl variant="outlined" fullWidth={true} >
                                        <InputLabel>Período</InputLabel>
                                        <Select
                                        value={periodo}
                                        onChange={handlerPeriodos}
                                        input={<OutlinedInput labelWidth={60} />}
                                        >
                                        {
                                            periodos.map((data, index) => {
                                                return(
                                                    <MenuItem key={index} value={data.num}>{data.mes}</MenuItem>
                                                )
                                            })
                                        }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    <FormControl variant="outlined" fullWidth={true} >
                                        <InputLabel>Cuestionario</InputLabel>
                                        <Select
                                        value={selCue}
                                        onChange={handlerSelCue}
                                        input={<OutlinedInput labelWidth={105} />}
                                        >
                                        {
                                            examenes.map((data, index) => {
                                                if(data.tipo === 2){
                                                    return(
                                                        <MenuItem key={index} value={data._id} >{data.nombrexamen}</MenuItem>
                                                    )
                                                }else{
                                                    return(true)
                                                }
                                            })
                                        }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    {/* <FormControl variant="outlined" fullWidth={true}>
                                        <InputLabel>Entidad de trabajo</InputLabel>
                                        <Select
                                        value={selEntidad}
                                        onChange={handlerFormEnt}
                                        input={<OutlinedInput labelWidth={140} />}
                                        >
                                        {
                                            entidades.map((data, index) => {
                                                return(
                                                    <MenuItem key={index} value={data._id} >{data.S_entNombre}</MenuItem>
                                                )
                                            })
                                        }
                                        </Select>
                                    </FormControl> */}
                                     <TextField 
                                        label="Nombre del evaluador"
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth={true}
                                        // value={nombre}
                                        // onChange={handlerNombre}
                                    />
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    {/* {
                                        selEntidad === "" ? 
                                        <FormControl variant="outlined" fullWidth={true} disabled>
                                            <InputLabel>Evaluador</InputLabel>
                                            <Select
                                            value={selEvaluador}
                                            onChange={handlerFormEva}
                                            input={<OutlinedInput labelWidth={30} />}
                                            >
                                            {
                                                evaluadores.map((data, index) => {
                                                    return(
                                                        data.I_evaEntidad === selEntidad ? <MenuItem key={index} value={data._id} >{data.S_evaNombre}</MenuItem> : false
                                                    )
                                                })
                                            }
                                            </Select>
                                        </FormControl> : <FormControl variant="outlined" fullWidth={true} > <InputLabel>Evaluador</InputLabel>
                                            <Select
                                            value={selEvaluador}
                                            onChange={handlerFormEva}
                                            input={<OutlinedInput labelWidth={100} />}
                                            >
                                            {
                                                evaluadores.map((data, index) => {
                                                    return(
                                                        data.I_evaEntidad === selEntidad ? <MenuItem key={index} value={data._id} >{data.S_evaNombre}</MenuItem> : false
                                                    )
                                                })
                                            }
                                            </Select>
                                        </FormControl>} */}
                                    <TextField 
                                        label="Correo del evaluador"
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth={true}
                                        // value={nombre}
                                        // onChange={handlerNombre}
                                    />
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    <Button variant="contained" color="primary" fullWidth={true} onClick={()=>{setDialog1(true)}}>
                                        Seleccionar recursos
                                    </Button>
                                </Grid>

                                <Grid item sm={6} xs={12}>

                                </Grid>
                                
                                { //Para visualizar la tarjeta de informacion del evaluador
                                    recArray.length === 0 ? null :  <Grid item xs={12}>
                                    <Card className={classes.card2}>
                                        <Grid container direction='row' spacing={2} justify="space-evenly" alignItems="center">

                                            <Grid item sm={4} xs={12}>
                                                <div align='center'>
                                                    <img src={evalData.S_evaImagen} alt="" height='200px' width='200px' className={classes.card3}/>
                                                </div>  
                                            </Grid>

                                            <Grid item sm={8} xs={12}>
                                                <Typography variant='h5' color="inherit" align="center">{evalData.S_evaNombre}</Typography>
                                                <Typography variant='subtitle1' color="inherit" align="center">{evalData.S_evaEntidad}</Typography>
                                                
                                                <div align='center'>
                                                    <List>
                                                        <ListSubheader>
                                                        {
                                                        recArray.length === 0 ? <Typography>El evaluador no tiene recursos asignados.</Typography>  : <Typography>Recursos asignados al evaluador:</Typography> 
                                                        }
                                                        </ListSubheader>
                                                        {
                                                            recArray.map((reg, index) =>{
                                                            return(
                                                                <ListItem key={index} >
                                                                <ListItemIcon>
                                                                    <Person />
                                                                </ListItemIcon>
                                                                <ListItemText primary={reg.AS_evaNombre} />
                                                                </ListItem>
                                                            );
                                                            })
                                                        }
                                                    </List>
                                                </div>

                                            </Grid>

                                        </Grid>
                    
                                    </Card>
                                </Grid>

                                }
                               

                                <Grid item  sm={5} xs={8}>
                                    <Button variant="contained" color="primary" className={classes.botonSend} fullWidth={true} onClick={handlerEnviarEval}>
                                        Enviar &nbsp;  
                                        <SendteIcon className={classes.rightIcon} />
                                    </Button>
                                </Grid>

                                </Grid> }

                           

                        </CardContent>

                    </Card>

                </Grid>

            </Grid>

            <div>
                <Dialog open={dialog1} onClose={()=>{setDialog1(false)}}>

                    <DialogTitle>Recursos para evaluar</DialogTitle>
                    <DialogContent>

                        <FormControl>
                            <FormGroup>
                            {
                                evaluadores.map((val,index)=>{
                                  return(
                                    <FormControlLabel
                                    key={index}
                                    control={<Checkbox checked={false} value={val.S_evaNombre} />}
                                    label={<Typography variant="subtitle1" > {val.S_evaNombre} </Typography>}
                                    />
                                  )
                                })
                            }

                            </FormGroup>
                        </FormControl>

                    </DialogContent>

                    <DialogActions>

                        <Button color="primary">
                            Cerrar
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>

        </div>
    );
};

Invitacion.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Invitacion);
