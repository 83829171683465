export const styles = theme => ({
    Primary: {
        backgroundColor: '#ee7203',
        color: '#fafafa'
    },
    PrimaryDark:{
        backgroundColor: '#b54300',
        color: '#f7ffff'
    },
    PrimaryLight: {
        backgroundColor: '#ffa241',
        color: '#b54300',
        fontFamily: 'Roboto'
    },
    Secondary: {
        backgroundColor: '#cccccb',
        color: '#000000'
    },
    SecondaryDark:{
        backgroundColor: '#9b9b9a',
        color: '#fafafa'
    },
    SecondaryLight: {
        backgroundColor: '#cccccc',
        color: '#000000'
    },
    FlotantePrincipal: {
        borderRadius: '50px'
    },
    ImagenGrande: {
        borderRadius: '50%',
        align: 'center',
        height: '200px', 
        width: '200px'
    },
    BotonPrincipal: {
        backgroundColor: '#ee7203',
        color: '#fafafa',
        borderRadius: '10px',
        height: '100%',
        width: 'auto'
    },
    BotonSend: {
        position: 'relative',
        margin: '0 auto',
        background: "#3aa832",
        "&:hover": {
            background: "#36b82c",
        }
    },
});
