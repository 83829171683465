import React, {useState, useEffect} from "react";
import axios from 'axios';
import MaterialTable from "material-table";
import {Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, Grid} from '@material-ui/core';
import {FormControl, InputLabel, Select, MenuItem, OutlinedInput} from '@material-ui/core';
import {TextField, Button, List, ListItem, ListItemText, ListItemIcon, ListSubheader} from '@material-ui/core';
import {GLOBAL} from "../usoGeneral/Global";
import { Typography } from "@material-ui/core";
import {Person} from '@material-ui/icons';
import FileBase64 from 'react-file-base64';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {styles} from '../usoGeneral/estilos';
import UploadIcon from '../usoGeneral/UploadIcon.svg';


function Evaluador(props){

  const { classes } = props;

  const [evaluadores, setEvaluadores] = useState([]); //arreglo con evaluadores 
  const [entidades , setEntidades] = useState([]); //arreglo con entidades
  const [control , setControl] = useState(0); //bandera de control
  const [selrow , setSelRow] = useState({}); //objeto con info de fila seleccionada
  const [dialog1 , setDialog1] = useState(false); //booleano de control Dialog 1 - añadir registro
  const [dialog2 , setDialog2] = useState(false); //booleano de control Dialog 2 - verdetalle de registro
  const [dialog3 , setDialog3] = useState(false); //booleano de control Dialog 3 - modificar registro
  const [dialog4 , setDialog4] = useState(false); //booleano de control Dialog 4 - Advertencia antes de borrado
  const [dialog5 , setDialog5] = useState(false); // Aviso 'no se pude borrar el evaluador';
  const [text1 , setText1] = useState(""); //string con nombre de nuevo evaluador
  const [text2 , setText2] = useState(""); //string con correo de nuevo evaluador
  const [archivo , setArchivo] = useState(""); //string con imagen de nuevo evaluador
  const [selEntidad , setSelEntidad] = useState(""); //controlador del combo de entidades
  const [recArray , setRecArray] = useState([]); //arreglo con recursos

  // Traer datos de evaluadores y entdades desde API
  const getData = () =>{
    axios 
    .get(GLOBAL+'registro?tipo=2')
    .then(response => {
        setEvaluadores(response.data);
    })
    .catch(error => {
        console.log(error);
    });

    axios 
    .get(GLOBAL+'registro?tipo=4')
    .then(response => {
        setEntidades(response.data);
    })
    .catch(error => {
        console.log(error);
    });

  };

  // Cerrar Dialogo1 y limpiar hooks
  const handlerDialog1 = () =>{
    setText1("");
    setText2("");
    setArchivo("");
    setDialog1(!dialog1);
  };

   // Cerrar Dialogo2 
   const handlerDialog2 = () =>{
    setDialog2(!dialog2);
    // setRecArray([]);
  };

  // Cerrar Dialogo3 
  const handlerDialog3 = () =>{
    setDialog3(!dialog3);
  };

   // Controlar Dialog4
   const handlerDialog4 = () => {
    setDialog4(!dialog4);
  };

  // Controlador de Dialog5
  const handlerDialog5 = () => {
    setDialog5(!dialog5);
  };

  // Recive datos para crear nuevo registro y manda a API **
  const handlerAddEntidad = () => {

    let entName ="";

    entidades.forEach(enti =>{ //Primero recuperamos el nombre de la entidad seleccionada
      if(enti._id === selEntidad){
        entName = enti.S_entNombre;
      }
    });

    let body = {
      tipo: 2,
      nombre: text1,
      correo: text2,
      entidad: entName,
      idEntidad: selEntidad,
      imagen: archivo
    };

    axios 
    .post(GLOBAL+'registro', body, {headers: {'Content-Type': 'application/json'}})
    .then(response => {
      handlerDialog1();
    })
    .catch(error => {
        alert(error);
    });

    
  };

  // Guarda String de Textfield  en hook
  const handlerTextField1 = (e) =>{
    setText1(e.target.value);
  };

  // Guarda String de Textfield  en hook
  const handlerTextField2 = (e) =>{
    setText2(e.target.value);
  };

  //Guarda imagen tipo string en hook
  const handleImg = (e) => {
    setArchivo(e.base64);
  };

  //Controla selección del combo de entidades
  const handlerFormEnt = (e) =>{
    setSelEntidad(e.target.value);
  };

  // Controlador de Dialog antes de borrar registro
  const handlerBorrar = () => {
    let recEva = selrow.A_evaRecursos;
    if(recEva.length === 0){
      handlerDialog3();
      handlerDialog4();
    }else{
      handlerDialog3();
      handlerDialog5();
    }
    
  };
  
  // Maneja los valores de las variables para edicion
  const editarRegistro = () => { 
    handlerDialog2();

    setText1(selrow.S_evaNombre);
    setText2(selrow.S_evaCorreo);
    setSelEntidad(selrow.I_evaEntidad); 
    setArchivo(selrow.S_evaImagen);

    handlerDialog3();
    
  };

  // Construir JSON y mandar cambios a la API
  const mandarCambios = () =>{

    let entName ="";
    entidades.forEach(enti =>{ //Primero recuperamos el nombre de la entidad seleccionada
      if(enti._id === selEntidad){
        entName = enti.S_entNombre;
      }
    });

    const body = {
      tipo:2,
      activo: true,
      id: selrow._id,
      nombre:text1,
      correo: text2,
      entidad: entName,
      idEntidad: selEntidad,
      imagen: archivo
    };

    axios.put(GLOBAL+'registro', body, {headers: {'Content-Type': 'application/json'}})
    .then(response => {
      handlerDialog3();
      setText1("");
      setText2("");
      setSelRow("");
      setSelRow({});
      setSelEntidad("");
      setArchivo("");
    })
    .catch(error => {
        console.log(error);
    });

  };

   // //Borrado lógico de registro con la API
   const borrarRegistro = () => {
    let body = {
      tipo: 2,
      activo: false,
      id: selrow._id
    };

    axios.put(GLOBAL+'registro', body, {headers: {'Content-Type': 'application/json'}})
    .then(response => {
      handlerDialog4();
      setText1("");
      setArchivo("");
      setSelRow({});
    })
    .catch(error => {console.log(error);});

  };


  useEffect(() => {
    if(control === 0){
      getData();
      setControl(1);
      // console.log(evaluadores)
    }
  });
  
  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable  
        title=""

        columns={[
          // { title: <Typography variant="subtitle1">ID</Typography>  , field: '_id' },
          // { title: <Typography variant="subtitle1">Imagen</Typography>  , field: 'S_evaImagen', render: rowData => <img src={rowData.S_evaImagen} alt="" style={{width: 30, height: 30, borderRadius: '50%'}}/> },
          { title: <Typography variant="subtitle1">Nombre</Typography> , field: 'S_evaNombre' },
          { title: <Typography variant="subtitle1">Entidad</Typography> , field: 'S_evaEntidad' },
          { title: <Typography variant="subtitle1">Correo</Typography> , field: 'S_evaCorreo'}
        ]}

        data={evaluadores}

        options={{
          filtering: false,
          headerStyle: {
            backgroundColor: '#ee7203',
            color: '#fafffb'
          }
        }}

        actions={[
          {
            icon: 'refresh',
            tooltip: 'Recargar',
            isFreeAction: true,
            onClick: () => {getData()},
          },
          {
            icon: 'add',
            tooltip: 'Añadir entidad',
            isFreeAction: true,
            onClick: (event) => setDialog1(true)
          }
        ]}

        localization={{
          body:{
            emptyDataSourceMessage: 'No hay registros por mostrar',
            filterRow:{filterTooltip: 'Filtro'},
          editRow:{
            deleteText: "¿Está seguro de borrar este registro?",
            saveTooltip: "Guardar",
            cancelTooltip: "Cancelar"
          }},
          header:{
            actions: "Acciones"
          },
          pagination:{
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'filas',
            firstTooltip: 'Primera página',
            previousTooltip: 'Página anterior',
            nextTooltip: 'Página siguiente',
            lastTooltip: 'Última página'
          },
          toolbar:{
            searchTooltip: 'Buscar',
            searchPlaceholder: 'Buscar'
          }
        }}

        onRowClick={((evt, selectedRow) => {
          setSelRow(selectedRow);
          handlerDialog2();
          setRecArray(selectedRow.A_evaRecursos);
        })}
        
      />
      {/* Diálogo con los componentes para añadir nuevo registro */}
      <Dialog open={dialog1} onClose={handlerDialog1} maxWidth='sm' fullWidth={true}>

        <DialogTitle className={classes.SecondaryDark}>
          <Typography align='center' variant="h5">Añadir nuevo evaluador</Typography>
        </DialogTitle>

        <DialogContent>

          <Grid container justify="center" alignItems="center" spacing={2}>

          <Grid item>
          <DialogContentText>
            Por favor añada los datos necesarios del evaluador.
          </DialogContentText>
          </Grid>

          <Grid item xs={11}>
          <TextField
            autoFocus
            id="name"
            label="Nombre del evaluador"
            value={text1}
            onChange={handlerTextField1}
            margin="normal"
            variant="outlined"
            fullWidth={true}
            />
          </Grid>

          <Grid item xs={11}>
          <TextField
            autoFocus
            id="mail"
            label="Correo del evaluador"
            value={text2}
            onChange={handlerTextField2}
            margin="normal"
            variant="outlined"
            fullWidth={true}
          />
          </Grid>
          
          <Grid item xs={11}>
          <FormControl variant="outlined" fullWidth={true}>
              <InputLabel>Entidad de trabajo</InputLabel>
              <Select
              value={selEntidad}
              onChange={handlerFormEnt}
              input={<OutlinedInput labelWidth={150} />}
              >
              {
                  entidades.map((data, index) => {
                      return(
                          <MenuItem key={index} value={data._id} >{data.S_entNombre}</MenuItem>
                      )
                  })
              }
              </Select>
          </FormControl>
          </Grid>

          <div align='center'>
            <img src={UploadIcon} className="uploadIcon" alt="Upload Icon" />
          </div>

          <Grid item>
          <FileBase64
            multiple={false}
            onDone={handleImg}
          />
          </Grid>
          <br />
          <br />
          <br />
          <Grid item xs={10}>
          <div align='center'>
          {
            archivo !== "" ? <img src={archivo} alt="Preview" width="50%" className={classes.ImagenGrande}/> : true
          }
          </div>
          </Grid>

        </Grid>

        </DialogContent>

        <DialogActions>

          <Button onClick={handlerDialog1} color="primary" className={classes.BotonPrincipal}>
            Cancelar
          </Button>

          <Button onClick={handlerAddEntidad} color="primary" className={classes.BotonPrincipal}>
            Añadir
          </Button>

        </DialogActions>

      </Dialog>

      {/* Diálogo que despliega info a detalle del registro y da opción a borrar o editar  */}
      <Dialog open={dialog2} onClose={handlerDialog2} maxWidth='sm' fullWidth={true}> 

        <DialogTitle className={classes.SecondaryDark}>
            <Typography align='center' variant="h5">{selrow.S_evaNombre}</Typography>
        </DialogTitle>

        <DialogContent>

          <Grid container justify="center" alignItems="center" spacing={2}>

            <Grid item xs={10}>
            <div align='center'>
                <img src={selrow.S_evaImagen} alt="" className={classes.ImagenGrande} />
            </div>
            </Grid>

            <Grid item xs={11}>
            <DialogContentText>
                <Typography variant="h6" align='center'>{selrow.S_evaEntidad}</Typography>
                <Typography variant="h6" align='center'>{selrow.S_evaCorreo}</Typography>
            </DialogContentText>
            </Grid>
        
            <List>
        
            <Grid item>
            <ListSubheader>
            {
              recArray.length === 0 ? <Typography>El evaluador no tiene recursos asignados.</Typography>  : <Typography>Recursos asignados al evaluador:</Typography> 
            }
            </ListSubheader>
            </Grid>

          {
            recArray.map((reg, index) =>{
              return(
                <ListItem key={index} >
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  <ListItemText primary={reg.AS_evaNombre} />
                </ListItem>
              );
            })
          }
          
            </List>


        </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handlerDialog2} color="primary" className={classes.BotonPrincipal}>
              Cerrar
          </Button>

          <Button onClick={editarRegistro} color="primary" className={classes.BotonPrincipal}>
              Editar
          </Button>
        </DialogActions>

      </Dialog>
      
      {/* Diálogo con los componentes para editar el registro*/}
      <Dialog open ={dialog3} onClose={handlerDialog3} maxWidth='sm' fullWidth={true}>

        <DialogTitle className={classes.SecondaryDark}>
          <Typography align='center' variant="h5">{selrow.S_evaNombre}</Typography>
        </DialogTitle>

        <DialogContent>

          <Grid container justify="center" alignItems="center" spacing={2}>
          
          <Grid item>
          <DialogContentText>
            Por favor modifique los datos necesarios del evaluador.
          </DialogContentText>
          </Grid>

          <Grid item xs={11}>
          <TextField
            autoFocus
            id="name"
            label="Nombre del evaluador"
            value={text1}
            onChange={handlerTextField1}
            margin="normal"
            variant="outlined"
            fullWidth={true}
          />
          </Grid>

          <Grid item xs={11}>
          <TextField
            autoFocus
            id="mail"
            label="Correo del evaluador"
            value={text2}
            onChange={handlerTextField2}
            margin="normal"
            variant="outlined"
            fullWidth={true}
          />
          </Grid>
          <Grid item xs={11}>
          <FormControl variant="outlined" fullWidth={true}>
              <InputLabel>Entidad de trabajo</InputLabel>
              <Select
              value={selEntidad}
              onChange={handlerFormEnt}
              input={<OutlinedInput labelWidth={150} />}
              >
              {
                  entidades.map((data, index) => {
                      return(
                          <MenuItem key={index} value={data._id} >{data.S_entNombre}</MenuItem>
                      )
                  })
              }
              </Select>
          </FormControl>
          </Grid>

          <div align='center'>
            <img src={UploadIcon} className="uploadIcon" alt="Upload Icon" />
          </div>

          <Grid item>
          <FileBase64
            multiple={false}
            onDone={handleImg}
          />
          </Grid>
          <br />
          <br />
          <br />
          <Grid item xs={10}>
          <div align='center'>
          {
            archivo !== "" ? <img src={archivo} alt="Preview" className={classes.ImagenGrande}/> : true
          }
          </div>
          </Grid>

          </Grid>

        </DialogContent>

        <DialogActions>

            <Button onClick={handlerDialog3} color="primary" className={classes.BotonPrincipal}>
              Cancelar
            </Button>

            <Button onClick={handlerBorrar} color="primary" className={classes.BotonPrincipal}>
              Borrar Evaluador
            </Button>

            <Button onClick={mandarCambios} color="primary" className={classes.BotonPrincipal}>
              Guardar cambios
            </Button>

        </DialogActions>

      </Dialog>
    
      {/* Dialog de advertencia antes del borrado lógico */}
      <Dialog open={dialog4} onClose={handlerDialog4} maxWidth='xs' fullWidth={true}>

        <DialogTitle className={classes.SecondaryDark}>
          <Typography align='center' variant="h5">Borrar Evaluador</Typography>
        </DialogTitle>

        <DialogContent>

          <Grid container justify="center" alignItems="center" spacing={2}>

          <Grid item>
          <DialogContentText>
            ¿Estás seguro de querer borrar el registro de {selrow.S_evaNombre} ?
          </DialogContentText>
          </Grid>

          </Grid>
        </DialogContent>

        <DialogActions>

          <Button onClick={handlerDialog4} className={classes.BotonPrincipal}>
            Cancelar
          </Button>

          <Button onClick={borrarRegistro} className={classes.BotonPrincipal}>
            Borrar
          </Button>

        </DialogActions>

      </Dialog>
    
      {/* Dialog de advertencia al no poder borrar registro */}
      <Dialog open={dialog5} onClose={handlerDialog5} maxWidth='xs' fullWidth={true}>

        <DialogTitle className={classes.SecondaryDark}>
          <Typography align='center' variant="h5">No se puede borrar este registro</Typography>
        </DialogTitle>

        <DialogContent>

          <Grid container justify="center" alignItems="center" spacing={2}>

          <Grid item>
          <DialogContentText>
          El evaluador que desea borrar tiene recursos asignados, primero reasigne a los recursos con otro evaluador antes de poder borrar.
          </DialogContentText>
          </Grid>

          </Grid>
        </DialogContent>

        <DialogActions>

          <Button onClick={handlerDialog5} className={classes.BotonPrincipal}>
            Cerrar
          </Button>

        </DialogActions>

      </Dialog>

    </div>
  );
  
}
Evaluador.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Evaluador);